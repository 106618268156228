.cart {
    width: 100%;
    padding-left: 15%;
    padding-right: 15%;
}

.cart>.content {
    width: 100%;
    display: flex;
}



.cart>.content>.cartResult>.title {
    margin-top: 60px;
    font-weight: bold;
    font-size: 36px;
    color: #410099;
    letter-spacing: -1px;
    line-height: 32px;
    margin-bottom: 15px;

}

.cart>.content>.cartResult>.subTitle {

    font-size: 14px;
    margin-left: 5px;
    margin-bottom: 30px;

}

.cart>.content>.cartResult {
    width: calc(100% - 360px);
}



.cart>.content>.cartResult>.book {
    width: 100%;
    border: 1px solid #dedede;
    background-color: #f9f9f9;

    border-radius: 7px;
    margin-bottom: 15px;
    box-shadow: 0 5px 5px 1px rgb(0 0 0 / 0%);
    align-items: center;
    position: relative;


}

.cart>.content>.cartResult>.emptyCart {
    width: 100%;

}

.cart>.content>.cartResult>.emptyCart>.emptyTitle {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 3px;
}

.cart>.content>.cartResult>.emptyCart>.emptyText {
    font-size: 14px;
    font-weight: bold;
    color: #666;
}

.cart>.content>.cartResult>.book>.bookContent {
    display: flex;
}

.cart>.content>.cartResult>.book>.bookContent>.photo {
    width: 200px;

    border-radius: 7px 0 0 7px;
    background-size: cover;
    position: relative;



}

.cart>.content>.cartResult>.book>.bookContent>.photo .imgs {
    position: absolute;
    top: 0;
    background-size: cover;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 7px 0 0 7px;
}

.cart>.content>.cartResult>.book>.bookContent>.photo .imgs>.countdown {
    position: absolute;
    bottom: 0px;
    background-color: #41009abd;
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: bold;
    padding-left: 10px;
    padding-right: 10px;
    color: #fff;
    font-size: 11px;
    width: -moz-fit-content;
    width: 100%;
    text-align: center;
    /* right: 15px; */
    border-radius: 0px 0px 0 7px;
}

.cart>.content>.cartResult>.book>.bookContent>.action {
    width: 200px;
}

.cart>.content>.cartResult>.book>.bookContent>.content {
    width: calc(100% - 400px);

    padding: 20px;
}

.cart>.content>.cartResult>.book>.bookContent>.content>.title {
    font-size: 16px;
    font-weight: bold;

}

.cart>.content>.cartResult>.book>.bookContent>.content>.package {
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 15px;
}

.cart>.content>.cartResult>.book>.bookContent>.content>.reservation,
.cart>.content>.cartResult>.book>.bookContent>.content>.startTime,
.cart>.content>.cartResult>.book>.bookContent>.content>.options {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.cart>.content>.cartResult>.book>.bookContent>.content>.reservation .date,
.cart>.content>.cartResult>.book>.bookContent>.content>.startTime .time {
    font-size: 12px;
    font-weight: bold;
}


.cart>.content>.cartResult>.book>.bookContent>.content>.reservation .icon,
.cart>.content>.cartResult>.book>.bookContent>.content>.startTime .icon {
    height: 18px;
    margin-right: 10px;
}

.cart>.content>.cartResult>.book>.bookContent>.content>.reservation img,
.cart>.content>.cartResult>.book>.bookContent>.content>.startTime img {
    height: 18px;
}

.cart>.content>.cartResult>.book>.bookContent>.content>.person>.priceBreakDown {
    margin-top: 30px;
    font-size: 14px;
    font-weight: bold;
    color: #41009a;
    border-bottom: 1px solid #dedede;
    margin-bottom: 10px;
    padding-bottom: 5px;
}

.cart>.content>.cartResult>.book>.bookContent>.content>.person>.row {
    display: flex;
    font-size: 12px;
    font-weight: bold;
    align-items: center;
    justify-content: space-between;
}

.cart>.content>.cartResult>.book>.bookContent>.content>.person>.row>div {
    margin-right: 10px;
}

.cart>.content>.cartResult>.book>.bookContent>.content>.person>.row>.icon {
    height: 18px;
    margin-right: 10px;
}

.cart>.content>.cartResult>.book>.bookContent>.content>.person>.row>.icon>img {
    height: 18px;
}

.cart>.content>.cartResult>.book>.bookContent>.summary {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-right: 20px;
    border-left: 1px solid #dedede;
    width: 200px;

}

.cart>.content>.cartResult>.book>.bookContent>.summary .price {
    font-size: 28px;
    color: #41009a;
    font-weight: bold;
    text-align: right;
}

.cart>.content>.cartResult>.book>.bookContent>.summary .priceDisc {
    text-decoration: line-through;
    font-size: 16px;
    color: #999;
    font-weight: bold;
    text-align: right;
}

.cart>.content>.cartResult>.book>.bookContent>.summary .tax {
    text-align: right;
    font-weight: bold;
    color: #41009a;
}

.cart>.content>.cartResult>.book>.bookContent>.summary .removeCart {
    font-weight: bold;
    border-radius: 7px;
    border: 2px solid #ff6c0e;
    text-align: center;
    font-size: 12px;
    padding-bottom: 5px;
    padding-top: 5px;
    padding-left: 20px;
    padding-right: 20px;
    cursor: pointer;
    color: #ffffff;
    background-color: #ff6c0e;
    margin-top: 15px;
}

.cart>.content>.cartResult>.book>.bookContent>.summary .edit {
    font-weight: bold;
    border-radius: 7px;
    border: 2px solid #41009a;
    text-align: center;
    font-size: 12px;
    padding-bottom: 5px;
    padding-top: 5px;
    padding-left: 20px;
    padding-right: 20px;
    cursor: pointer;
    color: #41009a;
    background-color: #fff;
    margin-top: 5px;
}

.cart>.content>.cartResult>.book>.bookContent>.summary .totalPriceTitle {
    font-size: 16px;
    text-align: right;
    font-weight: bold;
    color: #41009a;
}

.cart>.content>.cartResult>.book>.bookContent>.summary .saveTo {
    position: absolute;
    top: 0px;
    background-color: #ff6c0e;
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: bold;
    padding-left: 10px;
    padding-right: 10px;
    color: #fff;
    font-size: 14px;
    width: -moz-fit-content;
    width: fit-content;
    right: 15px;
}

.cart>.content>.bullet {
    padding: 30px;
    margin-top: 158px;
    width: 360px;
    border: 1px solid #dedede;
    background-color: #f9f9f9;
    border-radius: 7px;
    margin-bottom: 15px;
    box-shadow: 0 5px 5px 1px rgb(0 0 0 / 0%);
    margin-left: 20px;
}



.cart>.content>.bullet>.checkOut {
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
}

.cart>.content>.bullet>.checkOut>.grandTotal {
    
    font-size: 32px;
    font-weight: bold;
    color: #410099;
    letter-spacing: -1px;
    text-align: right;
    line-height: 21px;
    margin-top: 15px;
    margin-bottom: 15px;
}

.cart>.content>.bullet>.checkOut>.price {
    font-size: 28px;
    color: #41009a;
    font-weight: bold;
    text-align: right;
    letter-spacing: -1px;
    text-align: right;
  
    


}

.cart>.content>.bullet>.checkOut>.priceDisc {
    text-decoration: line-through;
    font-size: 16px;
    color: #999;
    font-weight: bold;
    text-align: right;
}

.cart>.content>.bullet>.checkOut>.tax {
    font-size: 12px;
    color: #410099;
    text-align: right;
    margin-bottom: 15px;
 
}

.cart>.content>.bullet>.checkOut .CheckOut {
    border-radius: 7px;
    border: 3px solid #ffffff;
    text-align: center;
    font-size: 17px;
    padding-bottom: 9px;
    padding-top: 9px;
   
    cursor: pointer;
    color: #fff;
    background-color: #41009a;
    height: 50px;
  
    margin-bottom: 15px;
}



.cart>.content>.bullet>.securePayment>.title {
    font-size: 14px;
    margin-bottom: 5px;
    color: #333;
    font-weight: bold;
   
}

.cart>.content>.bullet>.securePayment>.detail {
    font-size: 12px;
    color: #333;
    
}
.cart>.content>.bullet>.login {
   
    font-size: 18px;
    margin-bottom: 15px;
    color: #333;
}


/*

.cart>.content>.cartResult>.book>.bookContent {
    display: flex;
}

.cart>.content>.cartResult>.book>.timeLeft {
    margin-top: 30px;
    background-color: #f2f2f2;
    padding: 10px;
    border-top: 1px solid #dedede;
    border-bottom: 1px solid #dedede;

}

.cart>.content>.cartResult>.book>.bookContent>.photo>div {
    height: 100%;
}

.cart>.content>.cartResult>.book>.bookContent>.photo>div>img {

    width: 150px;
    border-radius: 0;
    background-color: #F9F9F9;
    margin: 10px;

}

.cart>.content>.cartResult>.book>.bookContent>.content {
    margin-left: 15px;
    width: calc(100% - 400px);
    padding-bottom: 15px;
}

.cart>.content>.cartResult>.book>.bookContent>.content>.title {
    font-family: "Avenir LT Pro 95 Black", Helvetica, Arial, Sans-Serif;
    font-size: 17px;
    color: #333;
    letter-spacing: .1px;
    padding-top: 10px;
    padding-bottom: 15px;
}

.cart>.content>.cartResult>.book>.bookContent>.content>.person {
    margin-top: 5px;
    margin-bottom: 5px;
    width: 100%;
}

.cart>.content>.cartResult>.book>.bookContent>.content>.person>.row {
    font-size: 15px;
    color: #333;
    display: flex;

}

.cart>.content>.cartResult>.book>.bookContent>.content>.person>.row>.icon {
    margin-right: 5px;
    padding-top: 2px;
}

.cart>.content>.cartResult>.book>.bookContent>.content>.person>.row>.icon>img {
    height: 16px;

}


.cart>.content>.cartResult>.book>.bookContent>.content>.person>.row>.per {
    width: 50%;
}

.cart>.content>.cartResult>.book>.bookContent>.content>.person>.row>.price {
    margin-left: 15px;
    font-family: "Avenir LT Pro 95 Black", Helvetica, Arial, Sans-Serif;
    width: 30%;
    text-align: right;
}


.cart>.content>.cartResult>.book>.bookContent>.content>.reservation {
    font-size: 15px;
    color: #333;
    display: flex;

}

.cart>.content>.cartResult>.book>.bookContent>.content>.reservation>.icon {
    margin-right: 5px;
    padding-top: 2px;
}

.cart>.content>.cartResult>.book>.bookContent>.content>.reservation>.icon>img {
    height: 16px;

}

.cart>.content>.cartResult>.book>.bookContent>.content>.startTime {
    font-size: 15px;
    color: #333;
    display: flex;

}

.cart>.content>.cartResult>.book>.bookContent>.content>.startTime>.icon {
    margin-right: 5px;
    padding-top: 2px;
}

.cart>.content>.cartResult>.book>.bookContent>.content>.startTime>.icon>img {
    height: 16px;

}


.cart>.content>.cartResult>.book>.bookContent>.content>.options {
    font-size: 15px;
    color: #333;
    display: flex;

}

.cart>.content>.cartResult>.book>.bookContent>.content>.options>.icon {
    margin-right: 5px;
    padding-top: 2px;
}

.cart>.content>.cartResult>.book>.bookContent>.content>.options>.icon>img {
    height: 16px;

}

.cart>.content>.cartResult>.book>.bookContent>.action {
    width: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-left: 1px solid #ccc;
    background-color: #f2f2f2;
    padding-left: 15px;

}

.cart>.content>.cartResult>.book>.bookContent>.action>div>.actionItem {
    display: flex;
    cursor: pointer;

}

.cart>.content>.cartResult>.book>.bookContent>.action>div>.actionItem>.icon>img {
    height: 14px;
}

.cart>.content>.cartResult>.book>.bookContent>.action>div>.actionItem>.text {
    font-family: "Avenir LT Pro 95 Black", Helvetica, Arial, Sans-Serif;
    font-size: 14px;
    padding-left: 5px;
    color: #410099;
}

.cart>.content>.cartResult>.book>.bookContent>.content>.optionsList {
    width: 100%;
}

.cart>.content>.cartResult>.book>.bookContent>.content>.optionsList>.option {
    display: flex;
    margin-left: 0px;
    margin-bottom: 0px;
}

.cart>.content>.cartResult>.book>.bookContent>.content>.optionsList>.option>.id {
    font-family: "Avenir LT Pro 95 Black", Helvetica, Arial, Sans-Serif;
    font-size: 14px;
    color: #333;
    margin-right: 15px;

}

.cart>.content>.cartResult>.book>.bookContent>.content>.optionsList>.option>.icon {
    width: 16px;
    margin-right: 5px;
}

.cart>.content>.cartResult>.book>.bookContent>.content>.optionsList>.option>.price {
    font-family: "Avenir LT Pro 95 Black", Helvetica, Arial, Sans-Serif;
    font-size: 14px;
    color: #333;
    margin-left: 15px;
    text-align: right;
    width: 30%;
}

.cart>.content>.cartResult>.book>.bookContent>.content>.optionsList>.option>.title {
    font-size: 14px;
    color: #333;
    width: 50%;

}

.cart>.content>.cartResult>.book>.bookContent>.action>.actionButton {
    display: flex;
    margin-top: 15px;
    justify-content: space-between;
    margin-right: 15px;
}

.cart>.content>.cartResult>.book>.bookContent>.action>div>.grandTotal {}

.cart>.content>.cartResult>.book>.bookContent>.action>div>.price {
    font-family: "Avenir LT Pro 95 Black", Helvetica, Arial, Sans-Serif;
    font-size: 26px;
    color: #333;
    letter-spacing: -1px;
    text-align: right;
    line-height: 21px;
    padding-left: 15px;
    padding-right: 15px;
}

.cart>.content>.cartResult>.book>.bookContent>.action>div>.taxInclude {
    font-family: "Avenir LT Pro 65 Medium", Helvetica, Arial, Sans-Serif;
    font-size: 12px;
    color: #410099;
    text-align: right;
    margin-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
}

.cart>.content>.bullet {
    padding: 30px;
    margin-top: 90px;
}

.cart>.content>.bullet>.checkOut {
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
}

.cart>.content>.bullet>.checkOut>.grandTotal {
    font-family: "Avenir LT Pro 95 Black", Helvetica, Arial, Sans-Serif;
    font-size: 16px;
    color: #410099;
    letter-spacing: -1px;
    text-align: right;
    line-height: 21px;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 5px;
}

.cart>.content>.bullet>.checkOut>.price {
    font-family: "Avenir LT Pro 95 Black", Helvetica, Arial, Sans-Serif;
    font-size: 26px;
    color: #333;
    letter-spacing: -1px;
    text-align: right;
    line-height: 21px;
    padding-left: 15px;
    padding-right: 15px;

}

.cart>.content>.bullet>.checkOut>.tax {
    font-family: "Avenir LT Pro 65 Medium", Helvetica, Arial, Sans-Serif;
    font-size: 12px;
    color: #410099;
    text-align: right;
    margin-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
}

.cart>.content>.bullet>.checkOut>.CheckOut {
    border-radius: 7px;
    border: 3px solid #ffffff;
    text-align: center;
    font-size: 17px;
    font-family: "Avenir LT Pro 95 Black", Helvetica, Arial, Sans-Serif;
    padding-bottom: 9px;
    padding-top: 9px;
    padding-left: 25px;
    padding-right: 25px;
    cursor: pointer;
    color: #fff;
    background-color: #41009a;
    height: 50px;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 15px;
}



.cart>.content>.bullet>.securePayment>.title {
    font-family: "Avenir LT Pro 95 Black", Helvetica, Arial, Sans-Serif;
    font-size: 14px;
    margin-bottom: 5px;
    color: #333;
}

.cart>.content>.bullet>.securePayment>.detail {
    font-family: "Avenir LT Pro 65 Medium", Helvetica, Arial, Sans-Serif;
    font-size: 12px;
    color: #333;
}
.cart>.content>.bullet>.login {
   
    font-family: "Avenir LT Pro 65 Medium", Helvetica, Arial, Sans-Serif;
    font-size: 18px;
    margin-bottom: 15px;
    color: #333;
}

.cart .booking {
    width: 100%;
    position: relative;
    padding: 15px;
    
}

.cart .booking>.title {
    font-family: "Avenir LT Pro 95 Black", Helvetica, Arial, Sans-Serif;
    font-size: 12px;
    color: #333;
    letter-spacing: .1px;
    font-size: 28px;
    margin-bottom: 30px;
}

.cart .booking>.title:after {
    width: 38px;
    height: 3px;
    background-color: #410099 !important;
    content: " ";
    display: block;
}

.cart .booking>.titleSub {
    font-family: "Avenir LT Pro 45 Book", Helvetica, Arial, Sans-Serif;
    font-size: 16px;
    margin-top: 0;
    margin-bottom: 15px;
    color: #333;
}

.cart .booking>.bookingPanel {
    background-color: #41009a;
    padding: 30px;
    margin-left: 0px;
    margin-right: 0px;
    display: flex;
    width: 100%;
    border: 1px solid #f9f9f9;
}

.cart .booking>.bookingPanel>.dates {
    position: relative;
    width: 50%;
}

.cart .booking>.bookingPanel>.dates>input {
    font-family: 'Avenir LT Pro 65 Medium', "AvenirNext-Medium";
    border-radius: 0;
    font-size: 16px !important;
    display: block;
    width: 100%;
    height: 50px;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 2px solid #dedede;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    padding-left: 45px;
}

.cart .booking>.bookingPanel>.dates>.icon {
    position: absolute;
    left: 13px;
    width: 24px;
    top: 13px;
}

.cart .booking>.bookingPanel>.person {
    margin-left: 15px;
    width: 50%;
    position: relative;
}

.cart .booking>.bookingPanel>.dates>.icon>img {
    width: 24px;
}

.cart .booking>.bookingPanel>.person>.icon>img {
    width: 24px;
}

.cart .booking>.bookingPanel>.person>.personInput {
    font-family: 'Avenir LT Pro 65 Medium', "AvenirNext-Medium";
    border-radius: 0;
    font-size: 16px !important;
    display: block;
    width: 100%;
    height: 50px;
    padding-top: 12px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 2px solid #dedede;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    padding-left: 45px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.cart .booking>.bookingPanel>.person>.personInput::after {
    border-style: solid;
    border-width: 0.15em 0.15em 0 0;
    content: '';
    display: inline-block;
    height: 0.45em;
    right: 15px;
    position: absolute;
    transform: rotate(-45deg);
    vertical-align: top;
    width: 0.45em;
    top: 20px;
    transform: rotate(135deg);
}

.cart .booking>.bookingPanel>.dates::after {
    border-style: solid;
    border-width: 0.15em 0.15em 0 0;
    content: '';
    display: inline-block;
    height: 0.45em;
    right: 15px;
    position: absolute;
    transform: rotate(-45deg);
    vertical-align: top;
    width: 0.45em;
    top: 20px;
    transform: rotate(135deg);
}

.cart .booking>.bookingPanel>.person>.icon {
    position: absolute;
    left: 13px;
    width: 24px;
    top: 13px;
}




.cart .booking>.bookingPanel>.person>.personPopup>.modalBack {
    background-color: rgba(255, 255, 255, .7);
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: -1;
    top: 0;
    left: 0;
}

.cart .booking>.bookingPanel>.person>.personPopup {
    position: absolute;
    width: 100%;
    border: 0px solid #333;
    padding: 15px;
    background-color: #fff;
    border-radius: 5px;
    top: 52px;
    z-index: 99;
    -webkit-box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6, 0 -1px 0 #e6e6e6, 0 3px 13px rgb(0 0 0 / 8%);
    box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6, 0 -1px 0 #e6e6e6, 0 3px 13px rgb(0 0 0 / 8%);
}

.cart .booking>.bookingPanel>.person>.personPopup>.totalPerson {
    font-family: "Avenir LT Pro 45 Book", Helvetica, Arial, Sans-Serif;
    font-size: 14px;
    margin-top: 0;
    margin-bottom: 30px;
    color: #333;
}

.cart .booking>.bookingPanel>.person>.personPopup>.personCalc {
    display: flex;
    margin-bottom: 20px
}

.cart .booking>.bookingPanel>.person>.personPopup>.personCalc>.personText {
    width: calc(100% - 120px);
}

.cart .booking>.bookingPanel>.person>.personPopup>.personCalc>.personText>.personTitle {
    font-family: "Avenir LT Pro 45 Book", Helvetica, Arial, Sans-Serif;
    font-size: 16px;
    margin-top: 0;
    margin-bottom: 0px;
    color: #333;
}

.cart .booking>.bookingPanel>.person>.personPopup>.personCalc>.personText>.personSpect {
    font-family: "Avenir LT Pro 45 Book", Helvetica, Arial, Sans-Serif;
    font-size: 12px;
    margin-top: 0;
    margin-bottom: 0;
    color: #333;
}

.cart .booking>.bookingPanel>.person>.personPopup>.personCalc>.personNum {
    display: flex;
    width: 120px;
    align-items: center;
    justify-content: space-between;
}

.cart .booking>.bookingPanel>.person>.personPopup>.personCalc>.personNum>.num {
    text-align: center;
    font-size: 18px;
    font-family: "Avenir LT Pro 85 Heavy", Helvetica, Arial, Sans-Serif;
}

.cart .booking>.bookingPanel>.person>.personPopup>.personCalc>.personNum>.minus, .cart .booking>.bookingPanel>.person>.personPopup>.personCalc>.personNum>.plus {
    cursor: pointer;
}

.cart .booking>.bookingPanel>.person>.personPopup>.personCalc>.personNum img {
    width: 28px;
    margin-top: 6px;
}

.cart .booking>.bookingPanel>.person>.personPopup>.setPerson {
    border-radius: 7px;
    border: 1px solid #41009a;
    text-align: center;
    font-size: 16px;
    font-family: "Avenir LT Pro 85 Heavy", Helvetica, Arial, Sans-Serif;
    padding-bottom: 12px;
    padding-top: 12px;
    padding-left: 25px;
    padding-right: 25px;
    cursor: pointer;
    color: #fff;
    background-color: #41009a;
    transition: .3s ease;
}





.cart .bookingResult {
    margin-top: 5px;
    border: 1px solid #f2f2f2;
    background-color: #f9f9f9;
    margin-top: 5px;
    padding-bottom: 15px;
}

.cart .bookingResult>.title {
    font-family: "Avenir LT Pro 95 Black", Helvetica, Arial, Sans-Serif;
    font-size: 12px;
    color: #333;
    letter-spacing: .1px;
    font-size: 21px;
    padding: 15px;
}

.cart .bookingResult>.content {
    width: 100%;
}

.cart .bookingResult>.content>.bookingDetails {
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 15px;
    display: flex;
    background-color: #fff;
    border-radius: 5px;
    top: 52px;
    z-index: 99;
    -webkit-box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6, 0 -1px 0 #e6e6e6, 0 3px 13px rgb(0 0 0 / 8%);
    box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6, 0 -1px 0 #e6e6e6, 0 3px 13px rgb(0 0 0 / 8%);
}

.cart .bookingResult>.content>.bookingDetails>.photo>div {
    height: 100%;
}

.cart .bookingResult>.content>.bookingDetails>.photo img {
    height: 100%;
    width: 140px;
    border-radius: 7px 0px 0px 7px;
    background-color: #F9F9F9;
    box-shadow: 0 5px 5px 1px rgb(0 0 0 / 5%);
}

.cart .bookingResult>.content>.bookingDetails>.bookInfo {
    width: calc(100% - 290px);
}

.cart .bookingResult>.content>.bookingDetails>.bookInfo>.title {
    margin-left: 10px;
    margin-top: 10px;
    font-family: "Avenir LT Pro 95 Black", Helvetica, Arial, Sans-Serif;
    font-size: 24px;
    letter-spacing: -0.5px;
}

.cart .bookingResult>.content>.bookingDetails>.bookInfo>.date {
    margin-left: 10px;
    margin-top: 0px;
    font-family: "Avenir LT Pro 85 Heavy", Helvetica, Arial, Sans-Serif;
    font-size: 16px;
}

.cart .bookingResult>.content>.bookingDetails>.totalPrice {
    margin-left: 7px;
    font-family: "Avenir LT Pro 85 Heavy", Helvetica, Arial, Sans-Serif;
    font-size: 20px;
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid #dedede;
    color: #ff7f2c;
    flex-wrap: wrap;
    align-content: center;
    flex-direction: column;
}

.cart .bookingResult>.content>.bookingDetails>.totalPrice>.orginalPrice {
    font-size: 14px !important;
    text-decoration: line-through;
    color: #999 !important;

}

.cart .bookingResult>.content>.bookingDetails>.bookInfo>.personText {
    margin-left: 10px;
    font-family: "Avenir LT Pro 85 Heavy", Helvetica, Arial, Sans-Serif;
    font-size: 16px;
}

.cart .bookingResult>.priceDetail {
    width: 100%;
    padding: 30px;
    border-left: 1px solid #f2f2f2;
    display: flex;
}

.cart .bookingResult>.priceDetail>.prices {
    width: 50%;
}

.cart .bookingResult>.priceDetail>.prices>div {}

.cart .bookingResult>.priceDetail>.prices>div>.title {
    font-size: 16px;
    font-family: "Avenir LT Pro 85 Heavy", Helvetica, Arial, Sans-Serif;
    padding-bottom: 12px;
    width: 33.33%;
}

.cart .bookingResult>.priceDetail>.prices>div>.title {
    font-size: 16px;
    font-family: "Avenir LT Pro 85 Heavy", Helvetica, Arial, Sans-Serif;
    padding-bottom: 12px;
    width: 50%;
    display: flex;
    justify-content: flex-end;
}

.cart .bookingResult>.priceDetail>.prices>.line {
    margin-top: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #666666;
}

.cart .bookingResult>.priceDetail>.prices>div>.price {
    font-size: 16px;
    font-family: "Avenir LT Pro 85 Heavy", Helvetica, Arial, Sans-Serif;
    padding-bottom: 12px;
    width: 100%;
}

.cart .bookingResult>.priceDetail>.prices>.total>.title {
    font-size: 16px;
    font-family: "Avenir LT Pro 85 Heavy", Helvetica, Arial, Sans-Serif;
    padding-bottom: 0px !important;
    width: 50%;
    display: flex;
    font-family: "Avenir LT Pro 85 Heavy", Helvetica, Arial, Sans-Serif;
    padding-bottom: 12px;
    width: 50%;
    display: flex;
    color: #a3a3a3;
    justify-content: flex-start;
    line-height: 15px;
}

.cart .bookingResult>.priceDetail>.prices>.total>.price {
    display: flex;
    position: relative;
}

.cart .bookingResult>.priceDetail>.prices>.total>.price>.totalPrice {
    font-size: 28px;
    font-family: "Avenir LT Pro 85 Heavy", Helvetica, Arial, Sans-Serif;
    padding-bottom: 0;
    display: flex;
    color: #ff7f2c;
    justify-content: flex-start;
    line-height: 35px;
    margin-top: 5px;
}

.cart .bookingResult>.priceDetail>.prices>.total>.price>.amountDetail {
    display: flex;
    align-items: end;
    font-size: 12px;
    margin-left: 15px;
    padding-bottom: 4px;
    cursor: pointer;
}

.cart .bookingResult>.priceDetail>.prices>.total>.price>.amountDetailPopup>.title {
    font-size: 16px;
    font-family: "Avenir LT Pro 85 Heavy", Helvetica, Arial, Sans-Serif;
    color: #a3a3a3;
    margin-bottom: 30px;
}

.cart .bookingResult>.priceDetail>.prices>.total>.price>.amountDetailPopup>.close {
    position: absolute;
    top: 5px;
    right: 10px;
    cursor: pointer;
}

.cart .bookingResult>.priceDetail>.prices>.total>.price>.amountDetailPopup {
    position: absolute;
    width: 100%;
    border: 0px solid #333;
    padding: 15px;
    background-color: #fff;
    border-radius: 5px;
    top: 52px;
    z-index: 99;
    -webkit-box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6, 0 -1px 0 #e6e6e6, 0 3px 13px rgb(0 0 0 / 8%);
    box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6, 0 -1px 0 #e6e6e6, 0 3px 13px rgb(0 0 0 / 8%);
}

.cart .bookingResult>.priceDetail>.prices>.total>.price>.amountDetailPopup>.info {
    display: flex;
    width: 100%;
    margin-bottom: 5px;
}

.cart .bookingResult>.priceDetail>.prices>.total>.price>.amountDetailPopup>.line {
    margin-top: 15px;
    margin-bottom: 15px;
}

.cart .bookingResult>.priceDetail>.prices>.total>.price>.amountDetailPopup>.info>div {
    display: flex;
    width: 50%;
    font-family: "Avenir LT Pro 45 Book", Helvetica, Arial, Sans-Serif;
    font-size: 14px;

}

.cart .bookingResult>.priceDetail>.prices>.total>.price>.amountDetailPopup>.info>div>div {
    margin-right: 5px;
}

.cart .bookingResult>.priceDetail>.prices>.total>.price>.amountDetailPopup>.info>div>.orginalPrice {
    text-decoration: line-through;
    color: #999 !important;
}

.cart .bookingResult>.priceDetail>.prices>.total>.price>.amountDetailPopup>.info>.price {
    display: flex;
    justify-content: flex-end;
}

.cart .bookingResult>.priceDetail>.prices>.total>.tax {
    font-family: "Avenir LT Pro 45 Book", Helvetica, Arial, Sans-Serif;
    font-size: 12px;
}

.cart .bookingResult>.priceDetail>.buttonSet {
    display: flex;
    width: 50%;
    justify-content: flex-end;
    align-content: stretch;
    align-items: center;
}

.cart .bookingResult>.priceDetail>.buttonSet>.bookNowButton {
    border-radius: 7px;
    border: 1px solid #41009a;
    text-align: center;
    font-size: 16px;
    font-family: "Avenir LT Pro 85 Heavy", Helvetica, Arial, Sans-Serif;
    padding-bottom: 12px;
    padding-top: 12px;
    padding-left: 25px;
    padding-right: 25px;
    cursor: pointer;
    color: #fff;
    background-color: #41009a;
    transition: .3s ease;
    height: 50px;
}

.cart .bookingResult>.priceDetail>.buttonSet>.addToCart {
    border-radius: 7px;
    border: 1px solid #ff7f2c;
    text-align: center;
    font-size: 16px;
    font-family: "Avenir LT Pro 85 Heavy", Helvetica, Arial, Sans-Serif;
    padding-bottom: 12px;
    padding-top: 12px;
    padding-left: 25px;
    padding-right: 25px;
    cursor: pointer;
    color: #fff;
    background-color: #ff7f2c;
    transition: .3s ease;
    height: 50px;
    margin-right: 15px;
}

.cart .bookingResult>.content>.timeOptions {
    margin-top: 15px;
}

.cart .bookingResult>.content>.error {
    margin: 15px 15px;
    padding: 12px;
    color: #D8000C;
    background-color: #FFD2D2;
}

.cart .bookingResult>.content>.timeOptions>.title {
    margin-left: 20px;
    font-family: "Avenir LT Pro 85 Heavy", Helvetica, Arial, Sans-Serif;
    font-size: 18px;
    padding-top: 15px;
    padding-bottom: 15px;
}


.cart .bookingResult>.content>.timeOptions>.buttonSet {
    width: 100%;
    display: flex;
    margin-left: 30px;
    margin-right: 30px;
}

.cart .bookingResult>.content>.timeOptions>.buttonSet>.timeButton {
    border-radius: 7px;
    border: 2px solid #41009a;
    text-align: center;
    font-size: 16px;
    font-family: "Avenir LT Pro 85 Heavy", Helvetica, Arial, Sans-Serif;
    padding-bottom: 12px;
    padding-top: 12px;
    padding-left: 25px;
    padding-right: 25px;
    cursor: pointer;

    background-color: white;
    transition: .3s ease;
    height: 50px;
    color: #41009a;
    margin-right: 15px;
}

.cart .bookingResult>.content>.timeOptions>.buttonSet>.timeButton.active {
    color: white;
    background-color: #41009a;
}

.cart .bookingResult>.content>.options {
    margin-top: 15px;
}

.cart .bookingResult>.content>.options>.titleBig {
    margin-left: 20px;
    font-family: "Avenir LT Pro 95 Black", Helvetica, Arial, Sans-Serif;
    font-size: 21px;
    padding-top: 30px;
    padding-bottom: 0;
}

.cart .bookingResult>.content>.options>.title {
    margin-left: 20px;
    font-family: "Avenir LT Pro 85 Heavy", Helvetica, Arial, Sans-Serif;
    font-size: 18px;
    padding-top: 15px;
    padding-bottom: 15px;
}

.cart .bookingResult>.content>.options>.optionsItem {
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 15px;
    display: flex;
    background-color: #f2f2f2;
    border-radius: 5px;
    padding: 15px;
    top: 52px;
    z-index: 99;
    -webkit-box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6, 0 -1px 0 #e6e6e6, 0 3px 13px rgb(0 0 0 / 8%);
    box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6, 0 -1px 0 #e6e6e6, 0 3px 13px rgb(0 0 0 / 8%);
}

.cart .bookingResult>.content>.options>.optionsItem>.optionsDetail {
    width: calc(100% - 420px);
}

.cart .bookingResult>.content>.options>.optionsItem>.optionsDetail>.item {
    font-family: "Avenir LT Pro 95 Black", Helvetica, Arial, Sans-Serif;
    font-size: 18px;
}

.cart .bookingResult>.content>.options>.optionsItem>.optionsDetail>.itemSub {
    font-family: "Avenir LT Pro 85 Heavy", Helvetica, Arial, Sans-Serif;
    font-size: 14px;
}

.cart .bookingResult>.content>.options>.optionsItem .personNum {
    display: flex;
    width: 120px;
    align-items: center;
    justify-content: space-between;
}

.cart .bookingResult>.content>.options>.optionsItem .num {
    text-align: center;
    font-size: 18px;
    font-family: "Avenir LT Pro 85 Heavy", Helvetica, Arial, Sans-Serif;
}

.cart .bookingResult>.content>.options>.optionsItem .minus, .cart .bookingResult>.content>.options>.optionsItem .plus {
    cursor: pointer;
}

.cart .bookingResult>.content>.options>.optionsItem .personNum img {
    width: 28px;
    margin-top: 6px;
}

.cart .bookingResult>.content>.options>.optionsItem>.optionsPlus {
    width: 120px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-content: center;
    margin-right: 15px;
    margin-left: 15px;
}

.cart .bookingResult>.content>.options>.optionsItem>.optionsPrice {
    text-align: center;
    font-size: 16px;
    font-family: "Avenir LT Pro 85 Heavy", Helvetica, Arial, Sans-Serif;
    width: 150px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-content: center;
    align-items: center;
    flex-direction: column;
}

.cart .bookingResult>.content>.options>.optionsItem>.optionsPrice>.orginalPrice {
    text-decoration: line-through;
    color: #999 !important;

    font-size: 14px;
}

.cart .bookingResult>.content>.options>.optionsItem>.optionsTotal {
    text-align: center;
    font-size: 18px;
    font-family: "Avenir LT Pro 85 Heavy", Helvetica, Arial, Sans-Serif;
    width: 150px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-content: center;
}
*/
.searchContainer {
    position: relative;
    width: 100%;
}

.searchContainer>.search {
    padding-top: 30px;
    position: relative;
    width: 100%;
    padding-left: 15%;
    padding-right: 15%;
    z-index: 6;
}

.searchContainer>.search>.searchPanels {
    display: flex;
}

.searchContainer>.search>.searchPanels>.filters {
    background-color: #F9F9F9;
    border: 1px solid #efefef;
    border-radius: 7px;
    box-shadow: 0 5px 5px 1px rgb(0 0 0 / 1%);
    width: 300px;
}

.searchContainer>.search>.searchPanels>.filters>.title {
    text-align: center;
    padding: 15px;
    color: #333;
    letter-spacing: -1px;
    font-size: 24px;
}

.searchContainer>.search>.searchPanels>.filters>.dates {
    background-color: #f2f2f2;

    padding: 15px;


}

.rc-slider-handle {
    width: 24px;
    height: 24px;
    cursor: pointer;
    cursor: -webkit-grab;
    margin-top: -10px;
}

.searchContainer>.search>.searchPanels>.filters>.filterItem {
    border-bottom: 1px solid #dedede;
    padding: 30px 15px 30px 15px;
}

.searchContainer>.search>.searchPanels>.filters>.filterItem>.elems>ul {
    margin: 0;
    padding-left: 0;
    padding-top: 15px;
    list-style: none;
}

.searchContainer>.search>.searchPanels>.filters>.filterItem>.elems>ul>li {
    margin-bottom: 5px;
    font-weight: bold;
}

.searchContainer>.search>.searchPanels>.filters>.filterItem>.title {
    text-align: left;
    /* padding: 15px; */
    color: #333;
    letter-spacing: -1px;
    font-size: 18px;
    font-weight: bold;
}

.searchContainer>.search>.searchPanels>.filters>.filterItem>.price {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px;
}

.searchContainer>.search>.searchPanels>.filters>.filterItem>.price>.priceVals {
    display: flex;
    justify-content: space-between;
    color: #41009a;
    font-weight: bold;

    padding-top: 5px;
    font-size: 13px;
    margin-left: -15px;
    margin-right: -15px;
}

.searchContainer>.search>.searchPanels>.filters>.dates>.title {
    color: #41009a;
    font-weight: bold;
    padding-bottom: 15px;
    font-size: 18px;
}

.searchContainer>.search>.searchPanels>.filters>.dates>.calendar {
    position: relative;
}

.searchContainer>.search>.searchPanels>.filters>.dates>.calendar>input {
    border-radius: 0;
    font-size: 16px !important;
    display: block;
    width: 100%;
    height: 50px;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 2px solid #dedede;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    padding-left: 45px;

}

.searchContainer>.search>.searchPanels>.filters>.dates>.calendar>.icon {
    position: absolute;
    left: 13px;
    width: 24px;
    top: 13px;
}

.searchContainer>.search>.searchPanels>.filters>.dates>.calendar>.icon>img {
    width: 24px;
}

.searchContainer>.search>.searchPanels>.filters>.dates>.button {
    border-radius: 25px;
    border: 1px solid #41009a;
    text-align: center;
    font-size: 16px;
    padding-bottom: 7px;
    padding-top: 7px;

    padding-left: 20px;
    padding-right: 20px;
    margin-top: 15px;
    margin-bottom: 0px;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
    color: #fff;
    transition: .3s ease;
    background-color: #41009a;
}

.searchContainer>.search>.searchPanels>.result {
    width: calc(100% - 300px);
    margin-left: 15px;
    position: relative;
}

.searchContainer>.search>.searchPanels>.result>a {
    text-decoration: none;
    color: #000;
}

.searchContainer>.search>.searchPanels>.result>.searchQuery {
    color: #41009a;
    font-weight: bold;
    padding-bottom: 15px;
    font-size: 24px;
}

.searchContainer>.search>.searchPanels>.result>.categories {
    padding-bottom: 0;
    padding-top: 5px;
    display: flex;
    width: 100%;
    border-bottom: 1px solid #f2f2f2;
    position: relative;

}
.searchContainer>.search>.searchPanels>.result>.categories>.catItems {
    display: flex;
    width: calc(100% - 35px);
    overflow-x: auto;
    position: relative;
}
.searchContainer>.search>.searchPanels>.result>.categories>.catItems::-webkit-scrollbar {
    width: 12px;
}

.searchContainer>.search>.searchPanels>.result>.categories>.catItems::-webkit-scrollbar-track {
    background: #f9f9f9;
}

.searchContainer>.search>.searchPanels>.result>.categories>.catItems::-webkit-scrollbar-thumb {
    background-color: #7a7a7a;
    border-radius: 10px;
    border: 3px solid #f9f9f9;
}

.searchContainer>.search>.searchPanels>.result>.categories>.arrows {
    position: absolute;
    right: 0px;
    top: 1px;
    bottom: 0;
    width: 45px;
    z-index: 106;
    background-image: -webkit-linear-gradient(right, #fff, rgba(255, 255, 255, 0));
    background-image: linear-gradient(to left, #fff, rgba(255, 255, 255, 0));
}

.searchContainer>.search>.searchPanels>.result>.categories>.arrows>.icon {
    height: 15px;
    width: 15px;
    z-index: 999;
    position: absolute;
    right: 5px;
    top: 14px;
    transform: rotate(270deg);

    opacity: 0.5;
}

.searchContainer>.search>.searchPanels>.result>.categories>.catItems>.catItem {
    border-radius: 7px;
    border: 1px solid #dedede;
    text-align: center;
    font-size: 14px;
    padding-bottom: 7px;
    padding-top: 7px;
    padding-left: 20px;
    padding-right: 20px;
    font-weight: bold;
    cursor: pointer;
    color: #333;
    transition: .3s ease;
    background-color: #fff;
    margin-right: 5px;
    margin-bottom: 15px;
    white-space: nowrap;
    box-shadow: 0 5px 5px 1px rgb(0 0 0 / 5%)
}

.searchContainer>.search>.searchPanels>.result>.categories>.catItems>.catItem.active {
    color: #fff;
    background-color: #41009a;
}

.searchContainer>.search>.searchPanels>.result>.shorting {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    margin-top: 15px;
}

.searchContainer>.search>.searchPanels>.result>.shorting>.searchQueryCount {
    font-weight: bold;

    font-size: 12px;
}

.searchContainer>.search>.searchPanels>.result>.shorting>.shortButton .menuTitle {
    font-size: 1.4rem;
}

.searchContainer>.search>.searchPanels>.result>.shorting>.shortButton ul {
    font-size: 12px;
    margin-left: 5px;
    word-break: break-all;
    text-decoration: none;
    display: block;
    color: #000;
    font-weight: bold;
}

.searchContainer>.search>.searchPanels>.result>.shorting>.shortButton ul>li {
    cursor: pointer;
}

.searchContainer>.search>.searchPanels>.result>.shorting>.shortButton .menu-container .menu {
    width: 260px;
    right: 0;
    left: auto;
    top: 30px;

}

.searchContainer>.search>.searchPanels>.result>a>.resultBody {
    width: 100%;
    border: 1px solid #dedede;
    background-color: #f9f9f9;
    border-radius: 7px;
    margin-bottom: 15px;
    box-shadow: 0 5px 5px 1px rgb(0 0 0 / 5%);
    align-items: flex-start;
    position: relative;
    display: flex;
    align-items: stretch;
    transition: .5s ease;
}

.searchContainer>.search>.searchPanels>.result>a>.resultBody:hover {
    box-shadow: 0 5px 10px 0px rgb(0 0 0 / 18%);
}

.searchContainer>.search>.searchPanels>.result>a>.resultBody>.photo {
    padding: 15px;
    width: 330px;
}

.searchContainer>.search>.searchPanels>.result>a>.resultBody>.photo>.search-pic {
    background-position: center;
    width: 300px;
    height: 200px;
    border-radius: 7px;
    background-size: cover;
    background-size: 100%;
    position: relative;
    transition: .5s ease;
}

.searchContainer>.search>.searchPanels>.result>a>.resultBody:hover>.photo>.search-pic {
    background-size: 110%;
    transition: .5s ease;


    -webkit-box-shadow: 0 10px 22px -8px rgb(0 0 0 / 30%);
    -moz-box-shadow: 0 10px 22px -8px rgba(0, 0, 0, .3);
    box-shadow: 0 10px 22px -8px rgb(0 0 0 / 30%);
}

.searchContainer>.search>.searchPanels>.result>a>.resultBody>.resultContent {
    width: calc(100% - 500px);
    padding-top: 15px;
    padding-bottom: 15px;
}

.searchContainer>.search>.searchPanels>.result>a>.resultBody>.resultContent>.productTitle {
    font-size: 16px;
    font-weight: bold;
    padding-bottom: 15px;
}


.searchContainer>.search>.searchPanels>.result>a>.resultBody>.resultContent>.productSpect {
    display: flex;
    align-items: center;
    margin-bottom: 3px;
    margin-top: 5px;
}

.searchContainer>.search>.searchPanels>.result>a>.resultBody>.resultContent>.productSpect.freecancel {
    position: absolute;

    bottom: 15px;
    color: #41009a !important;
}

.searchContainer>.search>.searchPanels>.result>a>.resultBody>.resultContent>.productSpect.freecancel>.icons {
    margin-right: 5px;
    height: 18px;
}

.searchContainer>.search>.searchPanels>.result>a>.resultBody>.resultContent>.productSpect.freecancel>.icons>img {
    width: 18px;
    height: 18px;
}

.searchContainer>.search>.searchPanels>.result>a>.resultBody>.resultContent>.productSpect.freecancel>.text {
    font-size: 14px;
    color: #41009a;
    font-weight: bold;

}

.searchContainer>.search>.searchPanels>.result>a>.resultBody>.resultContent>.productSpect>.icons {
    margin-right: 5px;
    height: 16px;
}

.searchContainer>.search>.searchPanels>.result>a>.resultBody>.resultContent>.productSpect>.icons>img {
    width: 16px;
    height: 16px;
}

.searchContainer>.search>.searchPanels>.result>a>.resultBody>.resultContent>.productSpect>.text {
    font-size: 12px;
    color: #000;
    font-weight: bold;

}

.searchContainer>.search>.searchPanels>.result>a>.resultBody>.resultContent>.productText {
    font-size: 11px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* number of lines to show */
    line-clamp: 3;
    -webkit-box-orient: vertical;

}

.searchContainer>.search>.searchPanels>.result>a>.resultBody>.resultPrice {
    width: 170px;
    padding: 15px;
    display: flex;
    align-items: flex-end;
    margin-right: 15px;
    flex-direction: column;
    justify-content: center;

}

.searchContainer>.search>.searchPanels>.result>a>.resultBody>.resultPrice>.bestPrice {
    font-size: 21px;
    color: #41009a;
    font-weight: bold;
    text-align: right;
    letter-spacing: -1px;
    text-align: right;
}

.searchContainer>.search>.searchPanels>.result>a>.resultBody>.resultPrice>.bestPriceFrom {
    font-size: 12px;
    font-weight: bold;
}

.searchContainer>.search>.searchPanels>.result>a>.resultBody>.resultPrice>.discountRate {
    position: absolute;
    top: 0px;
    background-color: #ff6c0e;
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: bold;
    padding-left: 10px;
    padding-right: 10px;
    color: #fff;
    font-size: 14px;
    width: -moz-fit-content;
    width: fit-content;
    right: 15px;
}

.searchContainer>.search>.searchPanels>.result>.bodyLoading {
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.7);
}

.searchContainer>.search>.searchPanels>.result>.bodyLoading>.tourzone-loader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}


.searchContainer>.search>.searchPanels>.result .wishlistProduct {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 99;
}

.searchContainer>.search>.searchPanels>.result .wishlistProduct>img {
    width: 32px;
}

.searchContainer>.search>.searchPanels>.result .resultDestination {
    position: absolute;
    /* margin-top: -12px; */
    background-color: #41009a;
    font-weight: bold;
    font-size: 12px;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 2px;
    padding-bottom: 2px;
    border-radius: 4px;
    margin-left: 15px;
    color: #fff;
    width: -moz-fit-content;
    width: fit-content;
    bottom: 10px;
}

.searchContainer>.search>.searchPanels>.result .resultCategories {
    position: absolute;
    /* margin-top: -12px; */
    background-color: #ff6c0e;
    font-weight: bold;
    font-size: 12px;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 2px;
    padding-bottom: 2px;
    border-radius: 4px;
    margin-left: 15px;
    color: #fff;
    width: -moz-fit-content;
    width: fit-content;
    bottom: 10px;
    right: 10px;
}


@media (max-width: 1440px) {
    .searchContainer{
        padding-left: 7%;
        padding-right: 7%;
    }
    .searchContainer>.search {
        padding-left: 0;
        padding-right: 0;
    }
}

@media (max-width: 1337px) {
    .searchContainer {
        padding-left: 3%;
        padding-right: 3%;
    }
    .searchContainer>.search {
        padding-left: 0;
        padding-right: 0;
    }
}

.checkout {
    width: 100%;
    padding-left: 15%;
    padding-right: 15%;
}



.checkout>.content {
    width: 100%;
    display: flex;
}

.checkout>.content>.bookingResult>.title {
    margin-top: 30px;
    font-size: 36px;
    color: #410099;
    letter-spacing: -1px;
    line-height: 32px;
    font-weight: bold;
}

.checkout>.content>.bookingResult>.subTitle {
    font-size: 16px;
    margin-left: 5px;
}

.checkout>.content>.bookingResult {
    width: calc(100% - 360px);
}

.checkout>.content>.bullet {
    top: 100px;
    position: -webkit-sticky;
    position: sticky;
    width: 360px;
    background-color: #F9F9F9;
    border: 1px solid #efefef;
    border-radius: 0px 0px 7px 7px;
    box-shadow: 0 5px 5px 1px rgb(0 0 0 / 5%);
    margin-top: 30px;
    margin-left: 15px;
    z-index: 9;
    height: fit-content;



}

.checkout>.content>.bullet>.photo>img {
    width: 100%;
}



.checkout>.content>.bullet>.paymentNow {
    border-radius: 7px;
    border: 3px solid #ffffff;
    text-align: center;
    font-size: 17px;
    padding-bottom: 9px;
    padding-top: 9px;
    padding-left: 25px;
    padding-right: 25px;
    cursor: pointer;
    color: #fff;
    background-color: #41009a;
    height: 50px;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 15px;

}

.checkout>.content>.bullet>.promoCode {
    font-size: 14px;
    color: #410099;

    text-align: right;
    margin-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
    cursor: pointer;
    text-decoration: underline;
}

.checkout>.content>.bullet>.inputParent>.addPromoCode {
    position: absolute;
    right: 9px;
    top: 9px;
}

.checkout>.content>.bullet>.inputParent>.addPromoCode>img {
    width: 32px;
}

.checkout>.content>.bullet>.inputParent>.title {
    position: absolute;
    z-index: 1;
    top: -7px;
    left: 15px;
    display: block;
    background: white;
    padding: 0 5px;
    font-size: 13px;
    white-space: nowrap;
}

.checkout>.content>.bullet>.inputParent>input {

    /* border-radius: 0; */

    display: block;
    width: 100%;
    height: 50px;
    /* padding: 15px 15px 15px 15px; */
    /* padding-left: 45px; */
    /* font-size: 1rem; */
    /* font-weight: 400; */
    /* line-height: 1.5; */
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #CACACA;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    /* padding-left: 5px; */
    padding-left: 21px;
}

.checkout>.content>.bullet>.inputParent {
    position: relative;
    margin: 25px;
}

.checkout>.content>.bullet>.grandTotal {
    padding: 15px;
}

.checkout>.content>.bullet>.grandTotal>.grandTotalTitle {
    font-size: 32px;
    font-weight: bold;
    color: #410099;
    letter-spacing: -1px;
    text-align: right;
    margin-bottom: 15px;


}

.checkout>.content>.bullet>.grandTotal>.price {


    font-size: 16px;
    color: #ccc;
    letter-spacing: -1px;
    text-align: right;
    line-height: 15px;
    text-decoration: line-through;


}



/**/


.guestInfo .cardInfo {
    width: 50%;
}

.guestInfo .creditCard {
    display: flex;
    width: 50%;
    justify-content: flex-end;
    align-items: center;
    position: relative;
}
.flex {
    display: flex;
}

.guestInfo .flex .mbr15 {
    margin-right: 15px;
}

.checkout .cards {
    width: 320px;
    height: 190px;
    -webkit-perspective: 600px;
    -moz-perspective: 600px;
    perspective: 600px;

}

.checkout .card__part {
    box-shadow: 1px 1px #aaa3a3;
    top: 0;
    position: absolute;
    z-index: 1000;
    left: 0;
    display: inline-block;
    width: 320px;
    height: 190px;
    background-image: linear-gradient(to right bottom, #41009a, #41009e, #4801ac, #5300c7, #5b00da);
    /*linear-gradient(to right bottom, #fd8369, #fc7870, #f96e78, #f56581, #ee5d8a)*/
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 8px;

    -webkit-transition: all .5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -moz-transition: all .5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -ms-transition: all .5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -o-transition: all .5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transition: all .5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
}

.checkout .card__front {
    padding: 18px;
    -webkit-transform: rotateY(0);
    -moz-transform: rotateY(0);
}

.checkout .card__back {
    padding: 18px 0;
    -webkit-transform: rotateY(-180deg);
    -moz-transform: rotateY(-180deg);
}

.checkout .card__black-line {
    margin-top: 5px;
    height: 38px;
    background-color: #303030;
}

.checkout .card__logo {
    height: 24px;
}

.checkout .card__front-logo {
    position: absolute;
    top: 18px;
    right: 18px;
}

.checkout .card__square {
    border-radius: 5px;
    height: 30px;
}

.checkout .card_numer {
    display: block;
    width: 100%;
    word-spacing: 4px;
    font-size: 20px;
    letter-spacing: 2px;
    color: #fff;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 20px;
}

.checkout .card__space-75 {
    width: 75%;
    float: left;
}

.checkout .card__space-25 {
    width: 25%;
    float: left;
}

.checkout .card__label {
    font-size: 10px;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.8);
    letter-spacing: 1px;
}

.checkout .card__info {
    margin-bottom: 0;
    margin-top: 5px;
    font-size: 16px;
    line-height: 18px;
    color: #fff;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.checkout .card__back-content {
    padding: 15px 15px 0;
}

.checkout .card__secret--last {
    color: #303030;
    text-align: right;
    margin: 0;
    font-size: 14px;
}

.checkout .card__secret {
    padding: 5px 12px;
    background-color: #fff;
    position: relative;
}

.checkout .card__secret:before {
    content: '';
    position: absolute;
    top: -3px;
    left: -3px;
    height: calc(100% + 6px);
    width: calc(100% - 42px);
    border-radius: 4px;
    background: repeating-linear-gradient(45deg, #ededed, #ededed 5px, #f9f9f9 5px, #f9f9f9 10px);
}

.checkout .card__back-logo {
    position: absolute;
    bottom: 15px;
    right: 15px;
}

.checkout .card__back-square {
    position: absolute;
    bottom: 15px;
    left: 15px;
}
/*
.checkout .cards:hover .card__front {
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);

}

.checkout .cards:hover .card__back {
    -webkit-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
}
*/
/**/




.checkout>.content>.bullet>.grandTotal>.spoPrice {


    font-size: 28px;
    color: #41009a;
    letter-spacing: -1px;
    text-align: right;
    font-weight: bold;




}

.checkout>.content>.bullet>.tax {


    font-size: 12px;
    color: #410099;

    text-align: right;
    margin-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
}

.checkout>.content>.bullet>.orders {

    background-color: #fff;
}

.checkout>.content>.bullet>.orders>.bookingContainer {
    display: flex;
    padding: 15px;
    border-top: 1px solid #dedede;
}

.checkout>.content>.bullet>.orders>.bookingContainer>.photo {
    width: 30%;
    margin-right: 15px;
}

.checkout>.content>.bullet>.orders>.bookingContainer>.photo>img {
    width: 100%;
    border-radius: 7px;
}

.checkout>.content>.bullet>.orders>.bookingContainer>.content {
    width: 70%;
}

.checkout>.content>.bullet>.orders>.bookingContainer>.content>.title {
    font-size: 12px;
    font-weight: bold;

}

.checkout>.content>.bullet>.orders>.bookingContainer>.content>.pr {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    font-weight: bold;
}


.checkout>.content>.bullet>.orders>.title {
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 30px;
    padding-bottom: 10px;
    font-size: 16px;
    font-weight: bold;
    text-align: right;
    color: #41009a;
    background-color: #dedede;


}

.flex-start {
    align-items: flex-start !important;
}

.errorInputs,
.errorInputs::placeholder {
    border-color: red !important;
    color: red !important;
    opacity: 0.8;
}


.checkout>.content>.bullet>.titlePerson {

    font-size: 12px;

    color: #333;
    letter-spacing: .1px;

}

.checkout>.content>.bullet>.detailParent {
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    padding-top: 15px;
}

.checkout>.content>.bullet>.detailParent>.title {

    font-size: 16px;

    color: #333;
}



.checkout>.content>.bullet>.detailParent>.titleDate {

    font-size: 12px;
    margin-top: 8px;
    color: #333;
    letter-spacing: .1px;
}

.checkout>.content>.bullet>.detailParent>.titlePerson {
    font-size: 12px;

    color: #333;
    letter-spacing: .1px;
}


.checkout>.content>.bullet>.cancellationPolicy {
    padding: 15px;
    background-color: #dedede;
}

.checkout>.content>.bullet>.cancellationPolicy>.title {
    font-size: 14px;
    margin-bottom: 5px;
    color: #333;
}

.checkout>.content>.bullet>.cancellationPolicy>.policy {
    font-size: 12px;

    color: #333;
}


.checkout>.content>.bullet>.securePayment {
    padding: 15px;

}


.checkout>.content>.bullet>.securePayment {
    padding: 15px;

}


.checkout>.content>.bullet>.securePayment>.title {

    font-size: 14px;
    margin-bottom: 5px;
    color: #333;
}


.checkout>.content>.bullet>.securePayment>.detail {

    font-size: 12px;

    color: #333;
}

.checkout>.content>.bookingResult>.productInfo {
    margin-top: 30px;

    width: 85%;

}

.checkout>.content>.bookingResult>.productInfo>.title {

    font-size: 14px;
    margin-bottom: 5px;
    color: #333;
}

.checkout>.content>.bookingResult>.productInfo>.require {

    font-size: 12px;
    margin-bottom: 5px;
    color: #333;
    text-align: right;
}

.checkout>.content>.bookingResult>.productInfo>.line {
    border-bottom: 1px solid #dedede;
    margin-top: 5px;
    margin-bottom: 5px;


}

.checkout>.content>.bookingResult>.productInfo>.inputParent {
    position: relative;
    margin-top: 25px;
}

.checkout>.content>.bookingResult>.productInfo>.inputParent>.title {
    position: absolute;
    z-index: 1;
    top: -7px;
    left: 15px;
    display: block;
    background: white;
    padding: 0 5px;
    font-size: 13px;
    white-space: nowrap;
}

.checkout>.content>.bookingResult>.productInfo>.inputParent>input {

    display: block;
    width: 100%;
    height: 45px;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #CACACA;
    border-radius: 5px;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    padding-left: 21px;
}

.checkout>.content>.bookingResult>.productInfo>.sameasbiling {
    margin-top: 10px;
    display: flex;
    margin-bottom: 30px;
}

.checkout>.content>.bookingResult>.productInfo>.needMoreInfo {

    font-size: 18px;
    color: #333;
    font-weight: bold;
    letter-spacing: -1px;
    text-align: left;
    margin-top: 30px;
    padding-top: 15px;
    border-top: 1px solid #dedede;

}

.checkout>.content>.bookingResult>.productInfo>.needMoreInfoProduct {

    font-size: 14px;
    color: #333;
    letter-spacing: -1px;
    text-align: left;



}

.checkout>.content>.bookingResult>.productInfo>.guestInfo>.row {
    display: flex;
    width: 100%;
    align-items: flex-end;
    margin-right: 15px;
}

.checkout>.content>.bookingResult>.productInfo>.guestInfo>.row>.inputParent {
    padding-right: 15px;
}

.checkout>.content>.bookingResult>.productInfo>.guestInfo>.row>.inputParent:last-child {
    padding-right: 0px;
}

.checkout>.content>.bookingResult>.productInfo>.guestInfo .inputParent {
    margin-top: 25px;
    width: 100%;
    position: relative;
}

.checkout>.content>.bookingResult>.productInfo>.guestInfo .inputParent>.title {
    position: absolute;
    z-index: 1;
    top: -7px;
    left: 1px;
    display: block;
    background: white;
    padding: 0 5px;
    font-size: 11px;
    white-space: nowrap;
}

.checkout>.content>.bookingResult>.productInfo>.payment {

    font-size: 24px;
    color: #333;
    letter-spacing: -1px;
    text-align: left;
    line-height: 21px;
    margin-top: 15px;

}

.checkout>.content>.bookingResult>.productInfo>.guestInfo .inputParent>input {

    /* border-radius: 0; */

    display: block;
    width: 100%;
    height: 50px;
    /* padding: 15px 15px 15px 15px; */
    /* padding-left: 45px; */
    /* font-size: 1rem; */
    /* font-weight: 400; */
    /* line-height: 1.5; */
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #CACACA;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    /* padding-left: 5px; */
    padding-left: 21px;
}

.react-select .react-select__control {
    height: 50px;
    border: 1px solid #cacaca;
}

.react-select .react-select__menu {
    z-index: 99;
}

.flatpickr-time {}

.checkout>.content>.bookingResult>.productInfo>.guestInfo.card {
    background-color: #f2f2f2;
    padding: 30px;
    border-radius: 12px;
    margin-top: 30px;
}

.mt0 {
    margin-top: 0px !important;
}

.react-tel-input .form-control {

    border-radius: 5px;
    width: 100% !important;
    outline: none;
    padding: 15px 1px 15px 58px !important;
    transition: box-shadow ease .25s, border-color ease .25s;
}

.react-tel-input .special-label {}


.checkout>.content>.bookingResult>.productInfo>.information {
    font-size: 12px;
}

.checkout>.content>.bookingResult>.productInfo>.paymentNow {
    border-radius: 7px;
    border: 3px solid #ffffff;
    text-align: center;
    font-size: 17px;

    padding-bottom: 9px;
    padding-top: 9px;
    padding-left: 25px;
    padding-right: 25px;
    cursor: pointer;
    color: #fff;
    background-color: #41009a;
    height: 50px;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 30px;
    margin-top: 30px;
}


.checkout>.content>.bookingResult>.productInfo>.guestInfo {
    /* background-color: #f2f2f2; */
    padding: 15px;
    border-radius: 7px;
    border: 1px solid #dedede;
    margin-top: 30px;
}

.checkout>.content>.bookingResult>.productInfo>.guestInfo>.title {

    font-size: 18px;
    color: #333;
}

.checkout>.content>.bookingResult>.productInfo>.guestInfo .flightTitle {

    font-size: 14px;
    margin-bottom: 5px;
    color: #333;
    margin-top: 30px;
    margin-bottom: 5px;
}


.checkout .maps {
    width: 100%;
    height: 350px;
    margin-top: 15px;
    border-radius: 7px;
}


.checkout>.content>.bookingResult>.productInfo>.pickupPointTitle {

    font-size: 18px;
    color: #333;
    letter-spacing: -1px;
    text-align: left;
    line-height: 21px;
    margin-top: 30px;
    margin-bottom: 15px;

}


.checkout>.content>.bookingResult>.productInfo>.errorMp {

    font-size: 16px;
    color: rgb(172, 4, 4);
    letter-spacing: -1px;
    text-align: left;
    line-height: 21px;
    margin-top: 15px;
    margin-bottom: 5px;

}


.checkout>.content>.bookingResult>.productInfo>.pickupPoinParent {
    background-color: #ffffff;
    padding: 15px;
    border-radius: 6px;
    border: 1px solid #f2f2f2;
    margin-top: 15px;
}


.radio-button {
    margin-bottom: 1rem;
}

.radio-button__label-wrapper {
    border-radius: 50%;
    cursor: pointer;
    display: block;
    padding-left: 2.5rem !important;
    position: relative;
    top: 0.25rem;
}

.radio-button__label-wrapper:hover .radio-button__input~.radio-button__custom-indicator {
    border-color: black;
}

.radio-button__label-title {
    margin-bottom: 0.25rem;
    font-size: 14px;
}

.radio-button__label-subtext {
    display: block;
    font-size: 12px;
    font-style: italic;
}

.radio-button__custom-indicator {
    background-color: white;
    border: 1px solid #999;
    border-radius: 50%;
    height: 24px;
    left: 0;
    position: absolute;
    top: 0;
    width: 24px;
}

.radio-button__custom-indicator::after {
    background-color: #41009a;
    border-radius: 50%;
    content: "";
    display: none;
    height: 14px;
    left: 4px;
    position: absolute;
    top: 4px;
    width: 14px;
}

.radio-button__input {
    opacity: 0;
    position: absolute;
    z-index: -1;
}

.radio-button__input:focus~.radio-button__custom-indicator {
    border-color: black;
}

.radio-button__input:checked~.radio-button__custom-indicator::after {
    display: block;
}

.radio-button__input:disabled~.radio-button__custom-indicator {
    opacity: 0.6;
    pointer-events: none;
}

[type="radio"]:checked+label:after,
[type="radio"]:not(:checked)+label:after {
    content: '';
    width: 10px;
    height: 10px;
    background: rgb(65, 0, 154);
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

[type="radio"]:not(:checked)+label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}

[type="radio"]:checked+label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}



.loadingCheckout {
    background-color: rgba(255, 255, 255, 0.8);
    position: fixed;
    display: flex;
    top: 0;
    width: 100%;
    height: 100vh;
    align-items: center;
    z-index: 99;
}

.completedBooking {
    width: 100%;
    padding-left: 15%;
    padding-right: 15%;
}

.completedBooking>.title {

    text-align: center;
    margin-top: 30px;
    font-size: 36px;

    letter-spacing: -1px;
    line-height: 32px;
    margin-bottom: 30px;
}

.completedBooking>.check {
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
}

.completedBooking>.check>img {
    width: 120px;
}
.completedBooking>.message {
    font-size: 18px;
    text-align: center;
}

.completedBooking>.img {
    text-align: center;
    margin-top: 60px;
}

.completedBooking>.img>img {
    width: 120px;
}






/**/

.ticketParent {
    max-width: 890px;
    margin-left: auto;
    margin-right: auto;

}

.borderTop {
    border-top: 1px dashed #404040;
}

.ticket {
    margin: auto;
    display: flex;
    background: white;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}

.tickets {
    margin: auto;

    background: white;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}

.ticket .left {
    display: flex;
}

.ticket .left .image {
    width: 250px;
    background-size: contain;
    opacity: 0.85;
}



.ticket .left .image img {
    width: 100%;
    height: 100%;
}


.ticket .middle {
    width: calc(100% - 430px);
    padding-left: 15px;
    padding-right: 15px;
}

.ticket .middle .voucherId {
    text-align: center;
    margin-top: 15px;
    font-family: "Avenir LT Pro 95 Black", Helvetica, Arial, Sans-Serif;
    font-size: 16px;
    letter-spacing: -1px;

}

.ticket .middle .date {
    margin-top: 15px;
    border-top: 1px solid gray;
    border-bottom: 1px solid gray;
    padding: 5px 0;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.ticket .middle .title {
    text-align: center;
    margin-top: 0px;
    font-family: "Avenir LT Pro 95 Black", Helvetica, Arial, Sans-Serif;
    font-size: 16px;
    letter-spacing: -1px;

}

.ticket .middle .text {
    text-align: center;
    margin-top: 5px;
    font-family: "Avenir LT Pro 95 Black", Helvetica, Arial, Sans-Serif;
    font-size: 21px;
    letter-spacing: -1px;

}





.ticket .right {
    width: 180px;
    border-left: 1px dashed #404040;
    display: flex;
    align-content: stretch;
    justify-content: center;
    align-items: center;

}

.ticket .middle .spect {

    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 15px;

}

.ticket .middle .spect .groups {
    margin-top: 5px;
    display: flex;
}

.ticket .middle .spect .groups .icon {
    width: 21px;
    height: 17px;
}

.ticket .middle .spect .groups .icon img {
    width: 100%;
}

.ticket .middle .spect .groups .text {
    margin-left: 10px;
    font-family: "Avenir LT Pro 65 Medium", Helvetica, Arial, Sans-Serif;
    font-size: 14px;
}

.ticket .right .barcode {
    text-align: center;
}

.tickets .titlePickup {
    text-align: center;
    padding-top: 15px;
    font-family: "Avenir LT Pro 95 Black", Helvetica, Arial, Sans-Serif;
    font-size: 24px;
    letter-spacing: -1px;
    width: 100%;
    margin-bottom: 15px;


}

.ticketParent .map {
    width: 100%;
    height: 300px;

}

.tickets .address {
    text-align: center;

    font-family: "Avenir LT Pro 95 Black", Helvetica, Arial, Sans-Serif;
    font-size: 16px;
    letter-spacing: -1px;
    width: 100%;
    padding-bottom: 15px;
}

.tickets .titles {
    text-align: center;
    padding-top: 15px;
    font-family: "Avenir LT Pro 95 Black", Helvetica, Arial, Sans-Serif;
    font-size: 24px;
    letter-spacing: -1px;
    width: 100%;
    margin-bottom: 15px;
}

.tickets .Information {
    padding-left: 15px;
    padding-right: 15px;
    font-family: "Avenir LT Pro 65 Medium", Helvetica, Arial, Sans-Serif;
    font-size: 14px;
    padding-bottom: 15px;
}









.productPage>.productContent>.left>.importantToKnow {
    width: 100%;
}

.Information .title {
    font-family: "Avenir LT Pro 95 Black", Helvetica, Arial, Sans-Serif;
    font-size: 12px;
    padding: 15px 15px 15px 0px;
    color: #333;
    letter-spacing: .1px;
    font-size: 28px;
    line-height: 1.5384616;
}

.Information .title:after {
    width: 38px;
    height: 3px;
    background-color: #410099 !important;
    content: " ";
    display: block;
}

.Information .content {
    width: 100%;
}

.Information .content>ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.Information .content>ul>li {
    display: flex;

}

.Information .content>ul>li>.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #9251ac;
    padding: 20px;
    align-self: center;
    margin: 0 20px;
    background: #410099;
    border-radius: 100%;
    width: 40px;
    padding: 40px;
    height: 40px;
    position: relative;
}

.Information .content>ul>li>.icon:after {
    content: "";
    width: 100%;
    height: 2px;
    background: #410099;
    position: absolute;
    left: 50%;
    z-index: -1;
    top: 50%;
    transform: translateY(-50%);
}

.Information .content>ul>li>.icon>img {
    width: 32px;
    height: 32px;
}

.Information .content>ul>li>.text {
    padding: 20px;
    font-size: 14px;
    background: #f2f2f2;
    width: 100%;
    border-radius: 0 6px 6px 0;
}

.Information .content>ul>li>.text>.title {
    font-family: "Avenir LT Pro 95 Black", Helvetica, Arial, Sans-Serif;
    font-size: 16px;
    line-height: 1.4;
    text-transform: uppercase;
    font-weight: 600;
    color: #333;
}

.Information .content>ul>li>.text>ul {
    flex-basis: 60%;
    font-family: "Avenir LT Pro 45 Book", Helvetica, Arial, Sans-Serif;
    list-style-type: square;
    margin-top: 15px;
}

.information {
    padding-bottom: 30px;
}

.information .text {
    padding-left: 30px;
    padding-right: 30px;
    font-family: "Avenir LT Pro 45 Book", Helvetica, Arial, Sans-Serif;
    text-align: left;
    margin-bottom: 15px;
}

.information .localPartner {
    display: flex;
    width: 50%;
    margin-left: 30px;
    justify-content: space-between;
    font-family: "Avenir LT Pro 45 Book", Helvetica, Arial, Sans-Serif;
    font-size: 14px;
}
.modals .errorImg {
    width: 30%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;

}

.modals .errorImg>img {
    width: 100%;
}

.modals .error {
    color:#41009a;
}

.modals .subTitle {
    font-size: 14px;
    margin-top: 5px;
}

.modals .tryAgainButton {
    margin-top: 30px;
    border-radius: 7px;
    border: 3px solid #ffffff;
    text-align: center;
    font-size: 17px;
    padding-bottom: 9px;
    padding-top: 9px;
    padding-left: 25px;
    padding-right: 25px;
    cursor: pointer;
    color: #fff;
    background-color: #41009a;
    height: 50px;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 15px;
}



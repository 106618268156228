.productPage .bookingResult {
    margin-top: 5px;
    width: 100%;

    margin-top: 30px;
    padding-bottom: 15px;
}




.productPage .bookingResult>.title {

    font-size: 12px;
    color: #333;
    letter-spacing: .1px;
    font-size: 21px;
    padding: 15px;
}

.productPage .bookingResult>.packages {
    width: 100%;
    display: flex;
}

.productPage .bookingResult>.packages>.packageContents {

    width: 75%;
    border: 1px solid #dedede;
    background-color: #f9f9f9;
    padding: 30px;

    border-radius: 7px;
    margin-bottom: 15px;
    box-shadow: 0 5px 5px 1px rgb(0 0 0 / 0%);
    align-items: center;
    position: relative;
    cursor: pointer;

}

.productPage .bookingResult>.packages>.left {
    width: 75%;
    border-right: 1px solid #dedede;
    padding-right: 30px;

}

.productPage .bookingResult>.packages>.left .photo {
    margin-top: 15px;
    width: 100%;
    height: 100px;
    display: none;
}

.productPage .bookingResult>.packages.active>.left .photo {
    display: block;
}

.productPage .bookingResult>.packages>.left .photo .swiper-container {
    margin-left: 0px;
    margin-right: 0px;
    width: 100%;
    height: 100px;
}

.productPage .bookingResult>.packages>.left .photo .sliderItem {
    height: 100px;
    background-size: cover;
    cursor: pointer;
    -webkit-transition: 250ms all;
    transition: 250ms all;
    border-radius: 7px;
}

.productPage .bookingResult>.packages>.left>.packagetitleParent>.packageName {
    font-size: 21px;
    font-weight: bold;
}

.productPage .bookingResult>.packages>.left>.packagetitleParent>.guideLanguage {
    background: #41009a;
    color: #fff;
    font-size: 10px;
    padding: 4px 7px 1px 7px;
    width: fit-content;
    text-transform: uppercase;
    margin-right: 15px;
    margin-bottom: 7px;
    font-weight: bold;
    border-radius: 4px;

}

.productPage .bookingResult>.packages>.left>.packagetitleParent>.packageDetail {
    margin-top: 7px;
    margin-left: 15px;

}

.productPage .bookingResult>.packages>.left>.packagetitleParent>.packageDetail>ul {
    font-size: 12px;
    padding: 0;
    margin: 0;
}


.productPage .bookingResult>.packages>.left>.packagetitleParent>.timeOption {
    width: 100%;
    margin-top: 15px;
}

.productPage .bookingResult>.packages>.left>.packagetitleParent>.timeOption>.selectStartTime {
    font-weight: bold;
    font-size: 12px;
    margin-bottom: 15px;
    color: #41009a;
}

.productPage .bookingResult>.packages>.left .cancelTime {
    margin-top: 30px;
    font-weight: bold;
    font-size: 12px;
}

.productPage .bookingResult>.packages>.left>.packagetitleParent>.timeOption>ul {
    list-style: none;
    margin-left: 0px;
    margin-top: 0px;
    padding: 0;
    display: flex;
    width: 100%;
}

.productPage .bookingResult>.packages>.left>.packagetitleParent>.timeOption>ul>li {
    margin-right: 10px;
    font-weight: bold;

    border-radius: 7px;
    border: 2px solid #41009a;
    text-align: center;
    font-size: 12px;
    padding-bottom: 7px;
    padding-top: 7px;
    padding-left: 20px;
    padding-right: 20px;
    width: 100px;
    cursor: pointer;
    color: #41009a;
    background-color: #fff;
    position: relative;
}

.productPage .bookingResult>.packages>.left>.packagetitleParent>.timeOption>ul>li.timeActive {

    color: #fff;
    background-color: #41009a;
}

.productPage .bookingResult>.packages>.left>.packagetitleParent>.timeOption>ul>li>.quotaLeft {
    position: absolute;
    bottom: -24px;
    left: -2px;


    padding-left: 5px;
    padding-right: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-left: auto;
    margin-right: auto;
    background-color: #ff6c0e;
    border-radius: 7px;
    color: #fff;
    width: 100px;
    line-height: normal;
    font-size: 8px;
    font-weight: bold;


}

.productPage .bookingResult>.packages>.right {
    width: 25%;
    position: relative;
    padding-left: 30px;
}

.productPage .bookingResult>.packages>.right>.totalPrice {
    position: relative;
}


.productPage .bookingResult>.packages>.right>.totalPrice>.title {
    font-size: 16px;
    text-align: right;
    color: #41009a;

}

.productPage .bookingResult>.packages>.right>.totalPrice>.title {
    font-size: 16px;
    text-align: right;
    font-weight: bold;

}

.productPage .bookingResult>.packages>.right>.totalPrice .pr {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;

}

.productPage .bookingResult>.packages>.right>.totalPrice .pr>.underlinePrice {
    text-decoration: line-through;
    font-size: 16px;
    color: #999;

    font-weight: bold;
}

.productPage .bookingResult>.packages>.right>.totalPrice .pr>.price {

    font-size: 28px;
    color: #41009a;
    font-weight: bold;

}

.productPage .bookingResult>.packages>.right>.totalPrice>.tax {
    text-align: right;
    font-weight: bold;
    color: #41009a;
}


.productPage .bookingResult>.packages>.right>.totalPrice>.selecPackage {

    font-weight: bold;

    border-radius: 7px;
    border: 2px solid #41009a;

    text-align: center;
    font-size: 12px;
    padding-bottom: 7px;
    padding-top: 7px;
    padding-left: 20px;
    padding-right: 20px;
    cursor: pointer;
    color: #fff;
    background-color: #41009a;
    margin-top: 15px;
}


.productPage .bookingResult>.packages>.right>.totalPrice>.addToCart {

    font-weight: bold;

    border-radius: 7px;
    border: 2px solid #41009a;

    text-align: center;
    font-size: 12px;
    padding-bottom: 10px;
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    cursor: pointer;
    color: #41009a;
    background-color: #fff;
    margin-top: 30px;
}

.productPage .bookingResult>.packages>.right>.totalPrice>.bookNow {

    font-weight: bold;

    border-radius: 7px;
    border: 2px solid #41009a;

    text-align: center;
    font-size: 12px;
    padding-bottom: 10px;
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    cursor: pointer;
    color: #fff;
    background-color: #41009a;
    margin-top: 10px;


}

.productPage .bookingResult>.packages>.right>.totalPrice>.priceBreakDownTitle {
    margin-top: 15px;
    font-weight: bold;
    font-size: 12px;
    margin-bottom: 15px;
    color: #41009a;

    display: flex;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
}

.productPage .bookingResult>.packages>.right>.totalPrice>.priceBreakDownTitle>.chevron {
    height: 12px;
}

.productPage .bookingResult>.packages>.right>.totalPrice>.priceBreakDownTitle img {
    height: 12px;
    margin-left: 3px;
    margin-right: 3px;
}

.productPage .bookingResult>.packages>.right>.totalPrice>.priceBreakDown>.breakDown>.blockName {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 70%;
}

.productPage .bookingResult>.packages>.right>.totalPrice>.priceBreakDown {
    width: 320px;
    position: absolute;
    background-color: #fff;
    border-radius: 7px;
    padding: 15px;
    right: 0;
    box-shadow: 0 5px 10px 0px rgb(0 0 0 / 18%);
    border-color: #41009a57;
    display: none;
    z-index: 99;
}

.productPage .bookingResult>.packages>.right>.totalPrice>.priceBreakDown.active {
    display: block;
}

.productPage .bookingResult>.packages>.right>.totalPrice>.priceBreakDown>.unit {
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 5px;
    color: #41009a;
}

.productPage .bookingResult>.packages>.right>.totalPrice>.priceBreakDown>.addOns {
    font-weight: bold;
    font-size: 14px;
    margin-top: 15px;
    margin-bottom: 5px;
}

.productPage .bookingResult>.packages>.right>.totalPrice>.priceBreakDown>.breakDown {

    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 5px;
}

.productPage .bookingResult>.packages>.right>.totalPrice>.disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.productPage .bookingResult>.packages>.left>.packagetitleParent>.options {
    margin-top: 30px;
    display: none;
}

.productPage .bookingResult>.packages.active>.packageContents {
    cursor: auto;
    box-shadow: -3px 0px 10px 0px rgb(0 0 0 / 18%);
    
    border-color: #41009a57;
    border-radius: 7px 0 0 7px;
    border-right: 0;
}

.productPage .bookingResult>.packages.active>.left>.packagetitleParent>.options {
    display: block !important;
}

.productPage .bookingResult>.packages>.left>.packagetitleParent>.options>.optionsTitle {
    font-size: 18px;
    color: #41009a;
    font-weight: bold;
    margin-bottom: 15px;
}

.productPage .bookingResult>.packages>.left>.packagetitleParent>.options>.option {
    background: #fff;
    margin-bottom: 15px;
    display: flex;
}

.productPage .bookingResult>.packages>.left>.packagetitleParent>.options>.option>.name {

    padding: 15px;
    width: 40%;

}


.productPage .bookingResult>.packages>.left>.packagetitleParent>.options>.option>.right {
    width: 60%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
}

.productPage .bookingResult>.packages>.left>.packagetitleParent>.options>.option>.name>.title {
    font-size: 14px;
    font-weight: bold;


}


.productPage .bookingResult>.packages>.left>.packagetitleParent>.options>.option .optionsPlus {
    width: 70px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-content: center;

}


.productPage .bookingResult>.packages>.left>.packagetitleParent>.options>.option .optionsPlus .personNum {
    display: flex;
    width: 80px;
    align-items: center;
    justify-content: space-between;
}

.productPage .bookingResult>.packages>.left>.packagetitleParent>.options>.option .optionsPlus .num {
    text-align: center;
    font-size: 14px;

}

.productPage .bookingResult>.packages>.left>.packagetitleParent>.options>.option .optionsPlus .minus,
.productPage .bookingResult>.packages>.left>.packagetitleParent>.options>.option .optionsPlus .plus {
    cursor: pointer;
}

.productPage .bookingResult>.packages>.left>.packagetitleParent>.options>.option .optionsPlus .personNum img {
    width: 20px;

}


.productPage .bookingResult>.packages>.left>.packagetitleParent>.options>.option>.right .underlinePrice {
    text-decoration: line-through;
    font-size: 12px;
    font-weight: bolder;
    color: #999;
}

.productPage .bookingResult>.packages>.left>.packagetitleParent>.options>.option>.right>.optionPrice {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #41009a;
    width: 120px;
}


.productPage .bookingResult>.packages>.left>.packagetitleParent>.options>.option>.right .price {

    font-size: 16px;
    font-weight: bolder;
}


.productPage .bookingResult>.packages>.saveTo {
    position: absolute;
    top: 0px;
    background-color: #ff6c0e;
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: bold;
    padding-left: 10px;
    padding-right: 10px;
    color: #fff;
    font-size: 14px;
    width: fit-content;
    right: 15px;

}


.productPage .bookingResult>.error {
    background: #ffe8ed;
    font-weight: 500;
    text-align: center;
    padding: 16px 0;
    font-size: 16px;
    margin-left: 15px;
    margin-right: 15px;
}

.productPage .bookingResult>.titleAlternative {
    color: #333;
    letter-spacing: .1px;
    font-size: 21px;
    padding: 15px;
    margin: 15px;
}

.productPage .bookingResult>.alternative {
    display: flex;
    flex-wrap: wrap;
    padding-left: 15px;
    padding-right: 15px;
}

.productPage .bookingResult>.alternative>.item {
    margin-right: 15px;
    border: 3px solid #000;
    padding-top: 7px;
    padding-bottom: 5px;
    border-radius: 10px;
    background: white;
    padding-left: 25px;
    padding-right: 25px;
    cursor: pointer;
    margin-bottom: 15px;
    font-size: 14px;
    font-weight: bold;
}

.productPage .bookingResult>.alternative>.item:hover {
    background-color: #41009a;
    color: white
}

/*new packages*/


.productPage .bookingResult>.packages>.packageContents>.packageHeader {
    display: flex;
    width: 100%;
    padding-bottom: 15px;

    align-items: center;

}

.productPage .bookingResult>.packages.active>.packageContents>.packageHeader {
    border-bottom: 1px solid #dedede;
}

.productPage .bookingResult>.packages>.packageContents>.packageHeader>.left {
    width: 50%;
}

.productPage .bookingResult>.packages>.packageContents>.packageHeader>.left>.likelyToSellOut {
    padding: 5px;
    background-color: #ff6c0e;
    border-radius: 4px;
    color: #fff;
    width: fit-content;
    line-height: normal;
    font-size: 10px;
    font-weight: bold;
    margin-bottom: 10px;
}

.productPage .bookingResult>.packages>.packageContents>.packageHeader>.left>.packageTitle {

    font-size: 21px;
    font-weight: bold;

}

.productPage .bookingResult>.packages>.packageContents>.packageHeader>.left>.guideLanguage {
    background: #41009a;
    color: #fff;

    padding: 3px 7px 3px 7px;
    width: -moz-fit-content;
    width: fit-content;
    text-transform: uppercase;
    margin-right: 15px;
    margin-bottom: 7px;
    font-weight: bold;
    border-radius: 4px;
    font-size: 12px;
    font-weight: bold;
    text-transform: capitalize;
}

.productPage .bookingResult>.packages>.packageContents>.packageHeader>.right {
    width: 50%;
    text-align: right;
}

.productPage .bookingResult>.packages>.packageContents>.packageHeader>.right>.totalPrice>.title {
    font-size: 16px;
    text-align: right;
    font-weight: bold;
}

.productPage .bookingResult>.packages>.packageContents>.packageHeader>.right>.totalPrice .pr {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
}

.productPage .bookingResult>.packages>.packageContents>.packageHeader>.right>.totalPrice .pr>.price {
    font-size: 28px;
    color: #41009a;
    font-weight: bold;
}

.productPage .bookingResult>.packages>.packageContents>.packageHeader>.right>.totalPrice>.tax {
    text-align: right;
    font-weight: bold;
    color: #41009a;
}

.productPage .bookingResult>.packages>.packageContents>.packageHeader>.selectedPackageIcons {
    margin-left: 15px;
}

.productPage .bookingResult>.packages>.packageContents>.packageHeader>.selectedPackageIcons>img {
    width: 40px;
    height: 40px;
}

.productPage .bookingResult>.packages>.packageContents>.packageBody {
    width: 100%;
    display: flex;

}

.productPage .bookingResult>.packages.active>.packageContents>.packageBody .content {
    display: block;
}

.productPage .bookingResult>.packages>.packageContents>.packageBody .content {
    width: 100%;
    display: none;
    border-top: 1px solid #dedede;
    padding-bottom: 15px;


}

.productPage .bookingResult>.packages>.packageContents>.packageBody .content>.timeOption {
    width: 100%;
    margin-top: 15px;
}

.productPage .bookingResult>.packages>.packageContents>.packageBody .content>.timeOption>.selectStartTime {
    font-weight: bold;
    font-size: 12px;
    margin-bottom: 15px;
    color: #41009a;
}

.productPage .bookingResult>.packages>.packageContents>.packageBody .content>.timeOption>ul {
    list-style: none;
    margin-left: 0px;
    margin-top: 0px;
    padding: 0;
    display: flex;
    width: 100%;
}

.productPage .bookingResult>.packages>.packageContents>.packageBody .content>.timeOption>ul>li {
    margin-right: 10px;
    font-weight: bold;
    border-radius: 7px;
    border: 2px solid #41009a;
    text-align: center;
    font-size: 12px;
    padding-bottom: 7px;
    padding-top: 7px;
    padding-left: 20px;
    padding-right: 20px;
    width: 100px;
    cursor: pointer;
    color: #41009a;
    background-color: #fff;
    position: relative;
}

.productPage .bookingResult>.packages>.packageContents>.packageBody .content>.timeOption>ul>li.timeActive {
    color: #fff;
    background-color: #41009a;
}




.productPage .bookingResult>.packages>.packageContents>.options {
    display: none;
}



.productPage .bookingResult>.packages.active>.packageContents>.options {
    display: block !important;
}

.productPage .bookingResult>.packages>.packageContents>.options>.optionsTitle {
    margin-top: 15px;
    font-size: 18px;
    color: #41009a;
    font-weight: bold;
    margin-bottom: 15px;
}

.productPage .bookingResult>.packages>.packageContents>.options>.option {
    background: #fff;
    margin-bottom: 15px;
    display: flex;
}

.productPage .bookingResult>.packages>.packageContents>.options>.option>.name {

    padding: 15px;
    width: 40%;

}


.productPage .bookingResult>.packages>.packageContents>.options>.option>.right {
    width: 60%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
}

.productPage .bookingResult>.packages>.packageContents>.options>.option>.name>.title {
    font-size: 14px;
    font-weight: bold;


}


.productPage .bookingResult>.packages>.packageContents>.options>.option .optionsPlus {
    width: 70px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-content: center;

}


.productPage .bookingResult>.packages>.packageContents>.options>.option .optionsPlus .personNum {
    display: flex;
    width: 80px;
    align-items: center;
    justify-content: space-between;
}

.productPage .bookingResult>.packages>.packageContents>.options>.option .optionsPlus .num {
    text-align: center;
    font-size: 14px;

}

.productPage .bookingResult>.packages>.packageContents>.options>.option .optionsPlus .minus,
.productPage .bookingResult>.packages>.packageContents>.options>.option .optionsPlus .plus {
    cursor: pointer;
}

.productPage .bookingResult>.packages>.packageContents>.options>.option .optionsPlus .personNum img {
    width: 20px;

}

.productPage .bookingResult>.packages>.packageContents>.options>.option>.right .underlinePrice {
    text-decoration: line-through;
    font-size: 12px;
    font-weight: bolder;
    color: #999;
}

.productPage .bookingResult>.packages>.packageContents>.options>.option>.right>.optionPrice {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #41009a;
    width: 120px;
}


.productPage .bookingResult>.packages>.packageContents>.options>.option>.right .price {

    font-size: 16px;
    font-weight: bolder;
}


.productPage .bookingResult>.packages>.packageContents>.packageFooter {
    width: 100%;
    display: flex;
    padding-top: 15px;
    border-top: 1px solid #dedede;

}

.productPage .bookingResult>.packages>.packageContents>.packageFooter>.buttons {
    display: flex;
}

.productPage .bookingResult>.packages>.packageContents>.packageFooter>.buttons>.addToCart {
    font-weight: bold;
    border-radius: 7px;
    border: 2px solid #41009a;
    text-align: center;
    font-size: 16px;
    padding-bottom: 10px;
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    cursor: pointer;
    color: #41009a;
    background-color: #fff;
    margin-right: 15px;

}

.productPage .bookingResult>.packages>.packageContents>.packageFooter>.buttons>.bookNow {
    font-weight: bold;
    border-radius: 7px;
    border: 2px solid #41009a;
    text-align: center;
    font-size: 16px;
    padding-bottom: 10px;
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    cursor: pointer;
    color: #fff;
    background-color: #41009a;

}

.productPage .bookingResult>.packages.active>.resultTickets {
    display: block;
    border-radius:0 7px 7px 0;
    box-shadow: 3px 0px 8px 0px rgb(0 0 0 / 10%);
    position: relative;
    border-left: 0px;
    border-color: #41009a57;

}

.productPage .bookingResult>.packages>.resultTickets {
    display: none;

    width: 25%;

    border: 1px solid #dedede;
    background-color: #f9f9f9;
  

    border-radius: 7px;
  
    box-shadow: 0 5px 5px 1px rgb(0 0 0 / 0%);
  
    position: relative;
    cursor: pointer;
    margin-bottom: 15px;
}



.productPage .bookingResult>.packages>.resultTickets .packageDesc {
    margin-top: 15px;
    text-align: left;
    font-size: 18px;
    margin-left: 30px;
    margin-right: 30px;
    height: 80px;
    overflow: hidden;;
}

.productPage .bookingResult>.packages>.resultTickets .seeAll {
   display: flex;
   margin-top: 15px;
   justify-content: center;
   padding-bottom: 15px;
   margin-left: 15px;
   margin-right: 15px;
   border-bottom: 1px solid #dedede;
}

.productPage .bookingResult>.packages>.resultTickets .seeAll>.text {
    text-align: center;
    font-size: 16px;
    color: #41009a;
   
}
.productPage .bookingResult>.packages>.resultTickets .seeAll>.icon {
    margin-left: 10px;
}

.productPage .bookingResult>.packages>.resultTickets .seeAll>.icon>img {
    width: 15px;
    height: 15px;
}

.productPage .bookingResult>.packages>.resultTickets .subTitle {
    margin-top: 10px;
    text-align: left;
    font-size: 14px;
    font-weight: bold;
    
}

.productPage .bookingResult>.packages>.resultTickets .subText ul {
    margin: 0px;
    padding: 0;
}

.productPage .bookingResult>.packages>.resultTickets .subText li {
    margin-left: 15px;
    text-align: left;
    font-size: 11px;
    
}




.productPage .bookingResult>.packages>.resultTickets .priceBreakDown>.breakDown>.blockName {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 70%;
}

.productPage .bookingResult>.packages>.resultTickets .priceBreakDown {


    margin-top: 15px;
    border-radius: 0px;
    padding: 15px;
    margin-right: 15px;
    margin-left: 15px;
    right: 0;
    border-bottom: 1px solid #dedede;
   
 
   
  
}


.productPage .bookingResult>.packages>.resultTickets .priceBreakDown>.unit {
    font-weight: bold;
    font-size: 21px;
    margin-bottom: 15px;
    color: #41009a;
}

.productPage .bookingResult>.packages>.resultTickets .priceBreakDown>.addOns {
    font-weight: bold;
    font-size: 14px;
    margin-top: 15px;
    margin-bottom: 5px;
}

.productPage .bookingResult>.packages>.resultTickets .priceBreakDown>.breakDown {

    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: 13px;
    font-weight: bold;
    margin-bottom: 5px;
}


.productPage .bookingResult>.packages>.resultTickets  .buttons {
   
}

.productPage .bookingResult>.packages>.resultTickets  .buttons>.addToCart {
    font-weight: bold;
    border-radius: 7px;
    border: 2px solid #41009a;
    text-align: center;
    font-size: 14px;
    padding-bottom: 10px;
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    cursor: pointer;
    color: #41009a;
    background-color: #fff;
    margin-right: 30px;
    margin-left: 30px;
    margin-top: 30px;


}

.productPage .bookingResult>.packages>.resultTickets  .buttons>.bookNow {
    font-weight: bold;
    border-radius: 7px;
    border: 2px solid #41009a;
    text-align: center;
    font-size: 14px;
    padding-bottom: 10px;
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    cursor: pointer;
    color: #fff;
    background-color: #41009a;
    margin-right: 30px;
    margin-left: 30px;
    margin-top: 15px;
    margin-bottom: 30px;

}

.productPage .bookingResult>.packages>.resultTickets  .buttons .disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.productPage .bookingResult>.packages>.resultTickets .cancelTime {
    margin: 30px;
    font-weight: bold;
    font-size: 12px;
} 


.bookingResultParent .modal {
    position: fixed;
}

.bookingResultParent .modal .productPage {

    height: calc(100vh - 200px);
    overflow: auto;
    overflow-y: scroll;
}

.modal .packageDesc {
    margin-top: 30px;
    text-align: left;
    font-size: 21px;
    
    margin-left: 30px;
    margin-right: 30px;

}

.modal .subTitle {
    margin-top: 30px;
    text-align: left;
    font-size: 16px;
    font-weight: bold;
    
}

.modal .subTitle img{
    width: 16px;
    height: 18px;
    padding-right: 3px;
    padding-top: 5px;
    margin-right: 5px;
}

.modal .subText ul {
    margin: 0px;
    padding: 0;
}

.modal .subText li {
    margin-left: 15px;
    text-align: left;
    font-size: 14px;
    
}


.productPage .bookingResult>.packages .photo {
    margin-top: 15px;
    width: 100%;
    padding-bottom: 15px;
   
}



.productPage .bookingResult>.packages .photo .swiper-container {
    margin-left: 0px;
    margin-right: 0px;
    width: 100%;
    height: 100px;
}

.productPage .bookingResult>.packages .photo .sliderItem {
    height: 100px;
    background-size: cover;
    cursor: pointer;
    -webkit-transition: 250ms all;
    transition: 250ms all;
    border-radius: 7px;
}

.productPage .bookingResult>.packages>.dot {
    width: 20px;
    margin: 10px 0;
    position: relative;
    background-color: #f9f9f9;
    z-index: 0;
    margin-bottom:25px;
    display: none;
}
.productPage .bookingResult>.packages.active>.dot {
    display: block;
}

.productPage .bookingResult>.packages>.dot::before {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    left: 0px;
    border-color: transparent transparent #fff #fff;
    border-style: solid;
    border-width: 5px;
    border-radius: 100%;
    top: -21px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.productPage .bookingResult>.packages>.dot::after {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    left: 0px;
    border-color: transparent transparent #fff #fff;
    border-style: solid;
    border-width: 5px;
    border-radius: 100%;
    bottom: -21px;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
}

.productPage .bookingResult>.packages>.dot>.topCircle {
    position: absolute;
    width: 19px;
    height: 19px;
    left: 1px;
    border-color: transparent transparent #41009a57 #41009a57;
    border-style: solid;
    border-width: 1px;
    border-radius: 100%;
    pointer-events: none;
    z-index: 1;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    top: -19px;
}

.productPage .bookingResult>.packages>.dot>.middle {
    border-left: 1px dashed #41009a57;
    width: 10px;
    margin-left: 10px;
    height: 100%;
}

.productPage .bookingResult>.packages>.dot>.bottomCircle {
    position: absolute;
    width: 19px;
    height: 19px;
    left: 1px;
    border-color: transparent transparent #41009a57 #41009a57;
    border-style: solid;
    border-width: 1px;
    border-radius: 100%;
    pointer-events: none;
    z-index: 1;
    bottom: -19px;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
}


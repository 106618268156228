.bookingNew {
    
    width: 100%;
}

.bookingNew .bookingNewTitle {
    font-weight: bold;
    color: #333;
    letter-spacing: .1px;
    font-size: 28px;
    margin-top: 30px;
    margin-bottom: 30px;
}

.bookingNew .bookingNewTitle:after {
    width: 38px;
    height: 3px;
    background-color: #410099 !important;
    content: " ";
    display: block;
}

.bookingNew .bookingEngineBody {
    padding: 15px;
    background-color: #F9F9F9;
    border: 1px solid #efefef;
    border-radius: 7px;
    box-shadow: 0 5px 5px 1px rgb(0 0 0 / 1%);
    width: calc(100% - 302px);
}

.bookingNew .bookingEngineBody .bookingCalendar {
    margin-top: 15px;
    margin-bottom: 30px;
}

.bookingNew .bookingEngineBody .bookingCalendar .calendarTitle {
    line-height: 18px;
    font-size: 16px;
    color: #666;
    font-weight: bold;
    margin-bottom: 15px;
}

.bookingNew .bookingEngineBody .bookingCalendar .bookingDateInput {
    position: relative;
    background-color: #41009a;
    border: 1px solid #41009a;
    color: #fff;
    border-radius: 5px;
    text-align: center;
    font-size: 16px;
    padding-bottom: 9px;
    padding-top: 9px;
    padding-left: 22px;
    padding-right: 0px;
    cursor: pointer;
    margin-right: 15px;
    font-family: 'Comfortaa';
    background: #41009a url("../Assets/icons/1.svg") left no-repeat;
    background-size: 22px;
    background-position-x: 9px;
    background-position-y: 6px;
    -webkit-font-smoothing: antialiased;
}

.bookingNew .bookingEngineBody .bookingCalendar .bookingDateInput::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: white;
    opacity: 1;
    /* Firefox */
}

.bookingNew .bookingEngineBody .bookingCalendar .bookingDateInput:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: white;
}

.bookingNew .bookingEngineBody .bookingCalendar .bookingDateInput::-ms-input-placeholder {
    /* Microsoft Edge */
    color: white;
}

.bookingNew .bookingEngineBody .packages {
    margin-bottom: 15px;
}

.bookingNew .bookingEngineBody .packages .packageTitle {
    line-height: 18px;
    font-size: 16px;
    color: #666;
    font-weight: bold;
    margin-bottom: 15px;
}

.bookingNew .bookingEngineBody .packages .packageItem {
    display: flex;
    flex-wrap: wrap;
}

.bookingNew .bookingEngineBody .packages .packageItem .package {
    border-radius: 5px;
    text-align: center;
    font-size: 14px;
    padding-bottom: 9px;
    padding-top: 9px;
    padding-left: 15px;
    padding-right: 15px;
    cursor: pointer;
    margin-right: 15px;
    font-weight: bold;
    height: 38px;
    margin-bottom: 15px;
}

.bookingNew .bookingEngineBody .packages .packageItem .package.default {
    border: 1px solid #555555;
    color: #555;
    background-color: white;
}

.bookingNew .bookingEngineBody .packages .packageItem .package.active {
    background-color: rgba(65, 0, 154, 0.20);
    border: 1px solid #41009a;
    color: #41009a;
    box-shadow: 0 5px 5px 1px rgb(0 0 0 / 1%);
}

.bookingNew .bookingEngineBody .guides {
    margin-bottom: 15px;
    margin-top: 30px;
}

.bookingNew .bookingEngineBody .guides .guideTitle {
    line-height: 18px;
    font-size: 16px;
    color: #666;
    font-weight: bold;
    margin-bottom: 15px;
}

.bookingNew .bookingEngineBody .guides .guideItem {
    display: flex;
    flex-wrap: wrap;
}

.bookingNew .bookingEngineBody .guides .guideItem .guide {
    border-radius: 5px;
    text-align: center;
    font-size: 14px;
    padding-bottom: 9px;
    padding-top: 9px;
    padding-left: 15px;
    padding-right: 15px;
    cursor: pointer;
    margin-right: 15px;
    font-weight: bold;
    height: 38px;
    margin-bottom: 15px;
}

.bookingNew .bookingEngineBody .guides .guideItem .guide img {
    width: 16px;
}

.bookingNew .bookingEngineBody .guides .guideItem .guide.default {
    border: 1px solid #555555;
    color: #555;
    background-color: white;
}

.bookingNew .bookingEngineBody .guides .guideItem .guide.active {
    background-color: rgba(65, 0, 154, 0.20);
    border: 1px solid #41009a;
    color: #41009a;
    box-shadow: 0 5px 5px 1px rgb(0 0 0 / 1%);
}

.bookingNew .bookingEngineBody .guides .guideItem .guide.disabled {
    background-color: #e1e1e1;
    border: 1px solid #ccc8c8;
    color: #989797;
}

.bookingNew .bookingEngineBody .sessions {
    margin-bottom: 15px;
    margin-top: 30px;
}

.bookingNew .bookingEngineBody .sessions .sessionTitle {
    line-height: 18px;
    font-size: 16px;
    color: #666;
    font-weight: bold;
    margin-bottom: 15px;
}

.bookingNew .bookingEngineBody .sessions .sessionItem {
    display: flex;
}

.bookingNew .bookingEngineBody .sessions .sessionItem .session {
    border-radius: 5px;
    text-align: center;
    font-size: 14px;
    padding-bottom: 9px;
    padding-top: 9px;
    padding-left: 15px;
    padding-right: 15px;
    cursor: pointer;
    margin-right: 15px;
    font-weight: bold;
    height: 38px;
    position: relative;
}

.bookingNew .bookingEngineBody .sessions .sessionItem .session.default {
    border: 1px solid #555555;
    color: #555;
    background-color: white;
}

.bookingNew .bookingEngineBody .sessions .sessionItem .session.active {
    background-color: rgba(65, 0, 154, 0.20);
    border: 1px solid #41009a;
    color: #41009a;
    box-shadow: 0 5px 5px 1px rgb(0 0 0 / 1%);
}

.bookingNew .bookingEngineBody .sessions .sessionItem .session.disabled {
    background-color: #e1e1e1;
    border: 1px solid #ccc8c8;
    color: #989797;
}

.loadingCalendarDay {
    color: #f2f2f2 !important;
}

.lds-ellipsis {
    display: inline-block;
    position: absolute;
    width: 45px;
    height: 15px;
    top: 20px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}

.lds-ellipsis div {
    position: absolute;
    top: 0px;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: rgb(78, 18, 102);
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
    left: 4px;
    animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
    left: 4px;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
    left: 16px;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
    left: 16px;
    animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0);
    }
}

@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(24px, 0);
    }
}

.disabledShash {
    box-sizing: border-box;
    position: absolute;
    display: block;
    transform: rotate(90deg) scale(var(--ggs, 1));
    width: 2px;
    height: 20px;
    background: currentColor;
    border-radius: 3px;
    color: #c1c1c1;
    top: 13px;
    /* right: 0; */
    left: 40px;
    margin-right: auto;
    margin-left: auto;
}

.guideCalendar {
    position: absolute;
    width: 100%;
    top: 8px;
    height: 5px;
    display: flex;
    justify-content: center;
}

.guideColor {
    width: 4px;
    height: 4px;
    margin-left: 4px;
    margin-right: 4px;
    border-radius: 50%;
}

.guide1 {
    background-color: #ff0000;
}

.guide2 {
    background-color: #0045fd;
}

.guide3 {
    background-color: #ded509;
}

.guide4 {
    background-color: #ff9800;
}


/*

*/

.personDiv {
    margin-top: 30px;
}

.personDiv>.title {
    line-height: 18px;
    font-size: 16px;
    color: #666;
    font-weight: bold;
    margin-bottom: 15px;
}

.personDiv>.option {
    background: #fff;
    margin-bottom: 15px;
    padding-top: 5px;
    display: flex;
    padding-bottom: 5px;
    border-radius: 7px;
    border: 1px solid #ccc;

}

.personDiv>.option>.name {
    padding: 15px;
    width: 40%;
}

.personDiv>.option>.name>.title {
    font-size: 14px;
    font-weight: 700;
}

.personDiv>.option>.right {
    width: 60%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
}

.personDiv>.option>.right>.optionPrice {
    position: relative;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #41009a;
    width: 33.33%;
    justify-content: center;
}

.personDiv>.option>.right>.optionPrice.end {
    align-items: flex-end !important;
    justify-content: flex-end !important;
}

.personDiv>.option>.right .price {
    font-size: 16px;
    font-weight: bolder;
    position: relative;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #41009a;
}

.personDiv>.option>.right .spo {
    font-size: 12px;
    font-weight: bolder;
    text-decoration: line-through;
    margin-right: 10px;
    color: #666;
}

.personDiv>.option .optionsPlus {
    width: 33.33%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
}

.personDiv>.option .optionsPlus .personNum {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.personDiv>.option .optionsPlus .minus,
.personDiv>.option .optionsPlus .plus {
    cursor: pointer;
}

.personDiv>.option .optionsPlus .num {
    position: relative;
    text-align: center;
    font-size: 14px;
    padding-left: 15px;
    padding-right: 15px;
}

.personDiv>.option .optionsPlus .personNum img {
    width: 20px;
}

.bookingNew .bookingEngineFooter {
  
    align-content: center;
    align-items: center;
}

.bookingNew .bookingEngineFooter .totalPrices {
    position: relative;
    font-size: 24px;
    line-height: 1.18;
    font-weight: bolder;
    color: #212121;
    margin-top: 45px;
    margin-left: 5px;
    margin-bottom: 30px;
    height: 30px;
    display: flex;
    justify-content: flex-end;

}

.bookingNew .bookingEngineFooter .buttons {
    display: flex;
}




.bookingNew .bookingBody {
    display: flex;
}

.bookingNew .packageDetails {
    background-color: #F9F9F9;
    border: 1px solid #efefef;
    border-radius: 7px;
    box-shadow: 0 5px 5px 1px rgb(0 0 0 / 1%);
    margin-left: 15px;
    z-index: 9;
    width: 305px;
}

.bookingNew .packageDetails {
    padding: 15px;
}

.bookingNew .packageDetails .packageDesc {
    height: 300px;
    overflow: auto;
    background-color: #f2f2f2;
    padding: 5px;
}


.bookingNew .packageDetails .packageDesc {
    scrollbar-width: auto;
    scrollbar-color: #7a7a7a #ffffff;
}

/* Chrome, Edge, and Safari */
.bookingNew .packageDetails .packageDesc::-webkit-scrollbar {
    width: 12px;
}

.bookingNew .packageDetails .packageDesc::-webkit-scrollbar-track {
    background: #f9f9f9;
}

.bookingNew .packageDetails .packageDesc::-webkit-scrollbar-thumb {
    background-color: #7a7a7a;
    border-radius: 10px;
    border: 3px solid #f9f9f9;
}

.bookingNew .packageDetails .titles1 {
    margin-top: 14px;
    font-weight: 700;
    font-size: 16px;

    color: #41009a;
}

.bookingNew .packageDetails .titles2 {

    font-weight: 700;
    font-size: 14px;
    margin-bottom: 15px;
    margin-top: 30px;
    color: #41009a;
}

.bookingNew .packageDetails .subTitle {
    margin-top: 10px;
    text-align: left;
    font-size: 16px;
    font-weight: 700;
}

.bookingNew .packageDetails .subText li {

    text-align: left;
    font-size: 11px;
    font-weight: 700;
}

.bookingNew .packageDetails .subTitle img {
    width: 16px;
    margin-left: 5px;
    margin-right: 5px;
}

.bookingNew .packageDetails .sliderItem {
    height: 160px;
    background-size: cover;
    cursor: pointer;
    transition: all .25s;
    border-radius: 7px;
}


.bookingNew .packageDetails .cancelTime {
    margin-top: 10px;
    margin-bottom: 10px;
    color: rgb(117, 117, 117);
    border: 1px solid rgb(117, 117, 117);
    background-color: rgba(245, 245, 245, 0);
    border-radius: 4px;

    line-height: 22px;
    font-size: 12px;
    padding: 0 8px;
    font-weight: bold;
}

.bookingNew .packageDetails .expand {
    display: flex;
    font-weight: 700;
    font-size: 14px;
    margin-bottom: 15px;
    margin-top: 15px;
    color: #41009a;
    font-weight: bold;
    cursor: pointer;

}

.bookingNew .packageDetails .expand img {
    height: 12px;
    margin-right: 5px;

}

.modalsNewPackage {
    width: 100%;
    padding-right: 18%;
    padding-left: 18%;
    display: flex;
}

.leftModal {
    width: 100%;

    position: fixed;
    z-index: 1143;
    top: 0;
    right: -100%;
    bottom: 0;
    -webkit-transition: all .9s ease-in-out;
    -moz-transition: all .9s ease-in-out;
    -o-transition: all .9s ease-in-out;
    transition: all .9s ease-in-out;
}

.leftModal.open {
    right: 0;
    width: 100%;
}


.modalMask {
    height: 100%;
    opacity: 1;
    transition: none;
    pointer-events: auto;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, .45);
}

.leftModal .modalwrapper {
    width: 100%;
    box-shadow: -6px 0 16px -8px rgb(0 0 0 / 8%), -9px 0 28px 0 rgb(0 0 0 / 5%), -12px 0 48px 16px rgb(0 0 0 / 3%);
    right: 0;
    height: 100%;
    position: absolute;
    transition: transform .3s cubic-bezier(.23, 1, .32, 1), box-shadow .3s cubic-bezier(.23, 1, .32, 1);
}

.leftModal .modalwrapper .modalContent {
    position: relative;
    z-index: 1;
    overflow: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    width: 100%;
    height: 100%;
}



.leftModal .modalwrapper .modalContent .modalHeader {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;

    color: rgba(0, 0, 0, .85);
    background: #fff;
    border-bottom: 1px solid #f0f0f0;
    border-radius: 2px 2px 0 0;
}

.leftModal .modalwrapper .modalContent .modalHeader .close {
    padding: 7px 15px 7px 15px;
    cursor: pointer;
}

.leftModal .modalwrapper .modalContent .modalHeader .close img {
    width: 24px;
}

.leftModal .modalwrapper .modalContent .modalHeader .title {
    font-size: 16px;
    font-weight: bolder;
}

.leftModal .modalwrapper .modalContent .modalBody {
    padding: 15px;
}

.noscroll {
    overflow: hidden;
}

.detailLeft {
    width: 368px;
}


.detailLeft .titles1 {
    margin-top: 14px;
    font-weight: 700;
    font-size: 21px;
    margin-bottom: 30px;

    color: #41009a;
}

.detailLeft .titles2 {

    font-weight: 700;
    font-size: 14px;
    margin-bottom: 15px;
    margin-top: 30px;
    color: #41009a;
}

.detailLeft .subTitle {
    margin-top: 10px;
    text-align: left;
    font-size: 16px;
    font-weight: 700;
}

.detailLeft .subText li {

    text-align: left;
    font-size: 11px;
    font-weight: 700;
}

.detailLeft .subTitle img {
    width: 16px;
    margin-left: 5px;
    margin-right: 5px;
}

.detailLeft .sliderItem {
    height: 160px;
    background-size: cover;
    cursor: pointer;
    transition: all .25s;
    border-radius: 7px;
}


.detailLeft .cancelTime {

    margin-bottom: 30px;
    color: rgb(117, 117, 117);
    border: 1px solid rgb(117, 117, 117);
    background-color: rgba(245, 245, 245, 0);
    border-radius: 4px;

    line-height: 22px;
    font-size: 12px;
    padding: 0 8px;
    font-weight: bold;
}

.detailMain {
    width: calc(100% - 368px);
    padding-left: 30px;
    padding-right: 30px;
}

.detailMain .sliderItem {
    height: 350px;
    background-size: cover;
    cursor: pointer;
    transition: all .25s;
    border-radius: 7px;
}

.detailMain .addOns {
    margin-top: 30px;
}

.detailMain .addOns .title {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.75rem;
}

.confirmationPopup {
    width: 100%;
    padding-right: 18%;
    padding-left: 18%;
    display: flex;
}

.confirmationPopup .productInfo {
    width: 368px;
}

.confirmationPopup .productInfo .productName {
    margin-top: 14px;
    font-weight: 700;
    font-size: 21px;
    margin-bottom: 30px;
    color: #41009a;
}

.confirmationPopup .productInfo .packageName {
    margin-top: 14px;
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 30px;
    color: #41009a;
}

.confirmationPopup .productInfo .reservationDate {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.confirmationPopup .productInfo .reservationDate .icon img {
    width: 24px;
    height: 24px;
}

.confirmationPopup .productInfo .reservationDate .text {
    margin-left: 10px;
    font-size: 1.4rem;
    font-weight: 700;
}

.confirmationPopup .productInfo .sessionTime {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.confirmationPopup .productInfo .sessionTime .icon img {
    width: 24px;
    height: 24px;
}

.confirmationPopup .productInfo .sessionTime .text {
    margin-left: 10px;
    font-size: 1.4rem;
    font-weight: 700;
}

.confirmationPopup .productInfo .guide {
    display: flex;
    align-items: center;
}

.confirmationPopup .productInfo .guide .icon img {
    width: 24px;
    height: 24px;
}

.confirmationPopup .productInfo .guide .text {
    margin-left: 10px;
    font-size: 1.4rem;
    font-weight: 700;
}

.confirmationPopup .productInfo .cancelTime {
    font-size: 1.2rem;
    margin-top: 15px;
    margin-bottom: 15px;
    font-weight: 700;
}

.confirmationPopup .productInfo .priceBreakDown {}

.confirmationPopup .productInfo .brackDownBody {
    display: flex;
}

.bookingNew .bookingAddOns {
    margin-top: 30px;
}


.bookingNew .bookingAddOns .title {
    line-height: 18px;
    font-size: 16px;
    color: #666;
    font-weight: bold;
    margin-bottom: 15px;
}


.bookingNew .bookingAddOns .addOnsBody {
    display: flex;
}

.bookingNew .bookingAddOns .addOnsBody .addOns {
    width: 30%;
    background: #fff;
    margin-bottom: 15px;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-right: 15px;
}

.bookingNew .bookingAddOns .addOnsBody .addOns .img {
    position: relative;
}

.bookingNew .bookingAddOns .addOnsBody .addOns .img img {
    width: 100%;
    border-radius: 5px 5px 0 0;
}


.bookingNew .bookingAddOns .addOnsBody .addOns .img .priceBar {
    position: absolute;
    bottom: 0;
    right: 0;
    margin-right: 5px;
    padding: 5px;
    color: #fff;
    font-size: 14px;
    background-color: #41009a;
}

.bookingNew .bookingAddOns .addOnsBody .addOns .optTitle {
    margin: 5px;
    line-height: 18px;
    font-size: 14px;
    color: #666;
    font-weight: bold;
    margin-bottom: 5px;
}

.bookingNew .bookingAddOns .addOnsBody .addOns .optDetail {
    margin: 5px;
    line-height: 18px;
    font-size: 12px;
    color: #666;
    font-weight: bold;
    margin-bottom: 15px;
}

.quantity {
    width: 100%;
    /* margin: 15px; */
    display: flex;
    justify-content: space-around;
    padding: 15px;
}

.quantity .optionsPlus {
    width: 33%;
    display: flex;
    /* flex-wrap: wrap; */
    justify-content: space-around;
    align-content: center;
}

.quantity .optionsPlus .personNum {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.quantity .optionsPlus .minus,
.quantity .optionsPlus .plus {
    cursor: pointer;
}

.quantity .optionsPlus .num {
    position: relative;
    text-align: center;
    font-size: 14px;
    padding-left: 15px;
    padding-right: 15px;
}

.quantity .optionsPlus .personNum img {
    width: 20px;
}


.bookingNew .bookingAddOns .spo {
    font-size: 10px;
    font-weight: bolder;
    text-decoration: line-through;
    margin-right: 10px;
    color: #ccc;
}

.bookingNew .bookingAddOns .discountTriangle {
    position: absolute;
    margin-top: 0px;
    background-color: #ff6c0e;
    font-weight: bold;
    font-size: 12px;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 2px;
    padding-bottom: 2px;
    border-radius: 4px;
    margin-left: 15px;
    color: #fff;
    width: fit-content;
    display: flex;
    top: 0;
}

.bookingNew .bookingAddOns .discountTriangle .rates {
 
    color: white;
}


.bookingNew .bookingAddOns .totalPrice {
    text-align: center;

    font-size: 16px;
    font-weight: bold;
}

.bookingNew .bookingBody .bookingEngineBody .wizard {
    width: 100%;
}

.bookingNew .bookingBody .bookingEngineBody .wizard .wizardHeard {
    width: 100%;
    display: flex;
    margin-bottom: 15px;
}


.bookingNew .bookingBody .step {
    padding: 0;
    list-style-type: none;
    font-family: arial;
    font-size: 12px;
    clear: both;
    line-height: 1em;
    margin: 0 -1px;
    text-align: center;
    width: 100%;
    display: flex;
    width:calc(100% - 20px);
}

.bookingNew .bookingBody .step li {
   
    padding: 10px 30px 10px 40px;
    background: #d3d3d3;
  
    position: relative;
    border-top: 1px solid #d3d3d3;
    border-bottom: 1px solid #d3d3d3;
    width: 50%;
    margin: 0 1px;
    display: flex;
    align-items: center;
}

.bookingNew .bookingBody .step li:before {
    content: '';
    border-left: 23px solid #fff;
    border-top: 23px solid transparent;
    border-bottom: 23px solid transparent;
    position: absolute;
    top: 0;
    left: 0;
    
}
.bookingNew .bookingBody .step li:after {
    content: '';
    border-left: 23px solid #d3d3d3;
    border-top: 23px solid transparent;
    border-bottom: 23px solid transparent;
    position: absolute;
    top: 0;
    left: 100%;
    z-index: 8;
}

.bookingNew .bookingBody .step li.active {
    background: #ff9800;
    border-top: 1px solid #ff9800;
    border-bottom: 1px solid #ff9800;
}

.bookingNew .bookingBody .step li.active:after {
    border-left-color: #ff9800;
}

.bookingNew .bookingBody .step li .number{
    text-align: center;
    font-size: 16px;
    margin-right: 30px;
    background-color: white;
    border-radius: 50%;
    line-height: 16px;
    padding-top: 5px;
    font-weight: bold;
    width: 25px;
    height: 25px;
    
}

.bookingNew .bookingBody .step li .number{
    color:#dedede
}

.bookingNew .bookingBody .step li.active .number{
    color:#666
}

.bookingNew .bookingBody .step li .stepTitle{
    color:white;
    font-size: 16px;
    font-weight: bold;
}

.bookingNew .bookingBody .wizardBody  {
    width: 100%;
    overflow: hidden;
    height: auto;
   
}



.bookingNew .bookingEngineFooter .buttons .back {
    font-weight: bold;
    border-radius: 7px;
    border: 2px solid #41009a;
    text-align: center;
    font-size: 16px;
    padding-bottom: 10px;
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    cursor: pointer;
    color: #41009a;
    background-color: #fff;
    margin-right: 15px;
    display: flex;
}


.bookingNew .bookingEngineFooter .buttons .reserve {
    font-weight: bold;
    border-radius: 7px;
    border: 2px solid #ff9800;
    text-align: center;
    font-size: 16px;
    padding-bottom: 10px;
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    cursor: pointer;
    color: #fff;
    background-color: #ff9800;
    display: flex;
}


.bookingNew .bookingEngineFooter .buttons .reserve.disable {
    
    background-color: #dedede;
    border: 2px solid #dedede;

}


.bookingNew .bookingEngineFooter .buttons .reserve img {
    width: 18px;
    margin-left: 5px;
}

.bookingNew .bookingEngineFooter .buttons .back img {
    width: 18px;
    margin-right: 5px;
}


.bookingNew .bookingEngineFooter .buttons .addToCart {
    


    font-weight: bold;
    border-radius: 7px;
    border: 2px solid #ff9800;
    text-align: center;
    font-size: 16px;
    padding-bottom: 10px;
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    cursor: pointer;
    color: #fff;
    background-color: #ff9800;
    display: flex;

}

.bookingNew .bookingEngineFooter .buttons .addToCart.disable {
    background-color: #dedede;
    border: 2px solid #dedede;
}

.bookingNew .bookingEngineFooter .buttons {
    display: flex;
    justify-content: space-between;
}

.bookingNew .bookingEngineFooter .buttons .right {
    display: flex;
}

.bookingNew .bookingEngineFooter .buttons .bookNow {
    font-weight: bold;
    border-radius: 7px;
    border: 2px solid #41009a;
    text-align: center;
    font-size: 16px;
    padding-bottom: 10px;
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    cursor: pointer;
    color: #fff;
    background-color: #41009a;
    margin-left: 15px;

}

.bookingNew .bookingEngineFooter .buttons .bookNow.disable {
    background-color: #dedede;
    border: 2px solid #dedede;
}

.bookingNew .soldOut {
    border-radius: 50%;
    background-color: #ff9800;
    color: #fff;
    display: inline-block;
    position: absolute;
    padding-left: 9px;
    padding-right: 9px;
    text-align: center;
    font-size: 14px;
    font-weight: bolder;
    top: -9px;
    right: -12px;
}

.bookingNew .session .tooltip {
    visibility: hidden;
    width: 141px;
    background-color: #ff9800;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 5px;
    position: absolute;
    z-index: 1;
    top: -43px;
    left: -38px;
    
  }

  .bookingNew .session .tooltip::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #ff9800 transparent transparent transparent;
  }
  
  .bookingNew .session:hover .tooltip{
    visibility: visible;
  }
